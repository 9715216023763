import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Button,
  Form,
  Modal,
  Dropdown,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaTrash, FaPlus, FaComment, FaTimes } from "react-icons/fa";
import NotepadLayout from "../Layout/NotepadLayout";
import axiosInstance from "../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Components/Loader";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2"; // Import SweetAlert2

// Styled component for issue and comment layout
const IssueCard = styled(Card)`
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CommentSection = styled.div`
  margin-top: 15px;
`;

const Comment = styled.div`
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  position: relative;
  padding-right: 50px; /* Space for delete icon */
`;

const ItalicDate = styled.p`
  font-style: italic;
  font-size: 12px;
  margin-bottom: 0;
`;

const CardTitleStyled = styled(Card.Title)`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
`;

// Styled <pre> tag that inherits the styles of CardTitleStyled
const PreStyled = styled.pre`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  white-space: pre-wrap; /* To preserve formatting inside <pre> */
  margin: 0; /* Adjust margins to be the same as CardTitleStyled */
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  float: right;
`;

// Function to get dropdown toggle color based on status
const getStatusColor = (status) => {
  switch (status) {
    case "pending to solve":
      return "danger";
    case "in progress":
      return "warning";
    case "solved":
      return "success";
    default:
      return "secondary";
  }
};

function StudentIssue() {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [activeIssueId, setActiveIssueId] = useState(null);
  const [showAddIssueModal, setShowAddIssueModal] = useState(false);
  const [newIssue, setNewIssue] = useState({
    description: "",
    status: "pending to solve", // Set default status here
  });
  const [activeTab, setActiveTab] = useState("pending to solve"); // Tracks the active tab
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [expandedComments, setExpandedComments] = useState({}); // Track expanded comments for each issue

  // Fetch issues dynamically based on selected status
  useEffect(() => {
    fetchIssues();
  }, [activeTab, startDate, endDate]);

  const fetchIssues = async () => {
    setLoading(true);
    try {
      let url = `/problems?status=${activeTab}`;

      // For "solved" tab, fetch last 7 days issues by default if no custom date range is selected
      if (activeTab === "solved") {
        const today = new Date();
        const lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);

        const start = startDate
          ? startDate.toISOString()
          : lastWeek.toISOString();
        const end = endDate ? endDate.toISOString() : today.toISOString();

        url += `&startDate=${start}&endDate=${end}`;
      }

      const response = await axiosInstance.get(url);
      setIssues(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
  };

  const handleAddComment = async (issueId) => {
    if (!newComment) return;

    try {
      const response = await axiosInstance.post(`/problem/${issueId}/comment`, {
        comment: newComment,
      });
      const newCommentData = response.data.comment;

      // Update the issue's comments locally without re-fetching the entire list
      setIssues(
        issues.map((issue) =>
          issue.problemId === issueId
            ? { ...issue, comments: [...issue.comments, newCommentData] }
            : issue
        )
      );

      setNewComment("");
      toast.success("Comment added successfully!");
    } catch (error) {
      handleError(error);
    }
  };

  // SweetAlert2 Confirmation for deleting a comment
  const handleDeleteComment = async (issueId, commentId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this comment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/problem/${issueId}/comment/${commentId}`);
          // Remove the comment locally
          setIssues(
            issues.map((issue) =>
              issue.problemId === issueId
                ? {
                    ...issue,
                    comments: issue.comments.filter(
                      (comment) => comment.commentId !== commentId
                    ),
                  }
                : issue
            )
          );
          Swal.fire('Deleted!', 'The comment has been deleted.', 'success');
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  // SweetAlert2 Confirmation for deleting an issue
  const handleDeleteIssue = async (issueId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this issue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/problem/${issueId}`);
          setIssues(issues.filter((issue) => issue.problemId !== issueId));
          Swal.fire('Deleted!', 'The issue has been deleted.', 'success');
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  // Function to add a new issue
  const handleAddIssue = async (e) => {
    e.preventDefault();

    if (!newIssue.description.trim()) {
      toast.error("Issue description is required!");
      return;
    }

    try {
      await axiosInstance.post("/problem", newIssue);
      fetchIssues(); // Refresh issues list after adding
      setShowAddIssueModal(false); // Close modal
      setNewIssue({ description: "", status: "pending to solve" }); // Reset form
      toast.success("Issue added successfully!");
    } catch (error) {
      handleError(error);
    }
  };

  const handleStatusChange = async (issueId, newStatus) => {
    try {
      await axiosInstance.put(`/problem/${issueId}/status`, {
        status: newStatus,
      });
      setIssues(
        issues.map((issue) =>
          issue.problemId === issueId ? { ...issue, status: newStatus } : issue
        )
      );
      toast.success("Status updated successfully!");
    } catch (error) {
      handleError(error);
    }
  };

  // Function to filter issues based on search query
  const filteredIssues = issues.filter((issue) =>
    issue.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <NotepadLayout>
      <Container>
        <h1 className="mb-4" style={{ fontWeight: "700", color: "#3f3f3f" }}>
          Student Issues
        </h1>

        <ActionButton
          variant="primary"
          className="mb-3"
          size="sm"
          onClick={() => setShowAddIssueModal(true)}
        >
          <FaPlus />
          Add Issue
        </ActionButton>

        {/* Nav for filtering issues based on status */}
        <Nav
          variant="underline"
          activeKey={activeTab}
          onSelect={(selectedTab) => setActiveTab(selectedTab)}
          className="mb-3"
        >
          <Nav.Item>
            <Nav.Link eventKey="pending to solve">Pending to Solve</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="in progress">In Progress</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="solved">Solved</Nav.Link>
          </Nav.Item>
        </Nav>

        {/* Show date range filter and search for solved issues */}
        {activeTab === "solved" && (
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {/* Search Input */}
        <Form.Group controlId="searchQuery" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search issues..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>

        {filteredIssues.length === 0 ? (
          <p>No issues found for this status.</p>
        ) : (
          filteredIssues.map((issue) => (
            <IssueCard key={issue.problemId}>
              <Card.Body>
                {/* Replaced CardTitleStyled with PreStyled to show issue description in <pre> */}
                <PreStyled>{issue.description}</PreStyled>
                <ItalicDate>
                  Date added: {new Date(issue.createdAt).toLocaleDateString()}
                </ItalicDate>
                <Card.Text>
                  Status:
                  <Dropdown size="sm">
                    <Dropdown.Toggle
                      variant={getStatusColor(issue.status)}
                      id="dropdown-basic"
                      size="sm"
                    >
                      {issue.status}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          handleStatusChange(
                            issue.problemId,
                            "pending to solve"
                          )
                        }
                      >
                        Pending to Solve
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleStatusChange(issue.problemId, "in progress")
                        }
                      >
                        In Progress
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleStatusChange(issue.problemId, "solved")
                        }
                      >
                        Solved
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Card.Text>

                <ActionButton
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteIssue(issue.problemId)}
                >
                  <FaTrash />
                  Delete Issue
                </ActionButton>

                <CommentSection>
                  <h5>Comments:</h5>
                  {issue.comments && issue.comments.length > 0 ? (
                    issue.comments
                      .slice(0, expandedComments[issue.problemId] ? issue.comments.length : 1)
                      .map((comment) => (
                        <Comment key={comment.commentId}>
                          <pre>{comment.comment}</pre>
                          <ItalicDate className="mt-3">
                            Commented Date:{" "}
                            {new Date(comment.createdAt).toLocaleDateString()}
                          </ItalicDate>
                          <Button
                            variant="danger"
                            size="sm"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                            }}
                            onClick={() =>
                              handleDeleteComment(
                                issue.problemId,
                                comment.commentId
                              )
                            }
                          >
                            <FaTrash />
                          </Button>
                        </Comment>
                      ))
                  ) : (
                    <p>No comments yet.</p>
                  )}

                  {issue.comments && issue.comments.length > 1 && (
                    <Button
                      variant="link"
                      onClick={() =>
                        setExpandedComments((prev) => ({
                          ...prev,
                          [issue.problemId]: !prev[issue.problemId],
                        }))
                      }
                    >
                      {expandedComments[issue.problemId] ? "Hide All" : "Show All"}
                    </Button>
                  )}

                  {activeIssueId === issue.problemId && (
                    <Form>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={5} // Set textarea rows for increased height
                          value={newComment}
                          placeholder="Add a comment"
                          onChange={(e) => setNewComment(e.target.value)}
                        />
                      </Form.Group>
                      <ActionButton
                        variant="primary"
                        size="sm"
                        onClick={() => handleAddComment(issue.problemId)}
                        disabled={!newComment}
                        className="mt-2"
                      >
                        <FaComment />
                        Submit
                      </ActionButton>
                    </Form>
                  )}
                  <Button
                    variant="link"
                    onClick={() =>
                      setActiveIssueId(
                        activeIssueId === issue.problemId
                          ? null
                          : issue.problemId
                      )
                    }
                  >
                    {activeIssueId === issue.problemId ? (
                      <>
                        <FaTimes />
                        Cancel
                      </>
                    ) : (
                      <>
                        <FaComment />
                        Add Comment
                      </>
                    )}
                  </Button>
                </CommentSection>
              </Card.Body>
            </IssueCard>
          ))
        )}

        {/* Add Issue Modal */}
        <Modal
          show={showAddIssueModal}
          onHide={() => setShowAddIssueModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddIssue}>
              <Form.Group controlId="issueDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter issue description"
                  rows={5} // Set textarea rows for increased height
                  value={newIssue.description}
                  onChange={(e) =>
                    setNewIssue({ ...newIssue, description: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="issueStatus" className="mt-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={newIssue.status}
                  onChange={(e) =>
                    setNewIssue({ ...newIssue, status: e.target.value })
                  }
                  size="sm"
                  disabled // Disable status select to default to "pending to solve"
                >
                  <option value="pending to solve">Pending to Solve</option>
                  <option value="in progress">In Progress</option>
                  <option value="solved">Solved</option>
                </Form.Control>
              </Form.Group>

              <ActionButton
                variant="primary"
                type="submit"
                className="mt-3"
                size="sm"
              >
                <FaPlus />
                Add Issue
              </ActionButton>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
      <ToastContainer />
    </NotepadLayout>
  );
}

export default StudentIssue;
