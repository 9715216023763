import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
  Badge,
} from "react-bootstrap";
import ReactSelect from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import Loader from "../Components/Loader";
import Pagination from "../Components/Pagination";
import SettingLayout from "../Layout/SettingLayout";
import Swal from "sweetalert2";
import AcademicLayout from "../Layout/AcademicLayout";

function BatchSubjects() {
  const [batches, setBatches] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [courseSubjects, setCourseSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [showSubjectAssignModal, setShowSubjectAssignModal] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [subjectAssignments, setSubjectAssignments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSubjectTerm, setSearchSubjectTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [batchStartDate, setBatchStartDate] = useState(""); // Start date of the batch
  const [batchEndDate, setBatchEndDate] = useState(""); // End date of the batch
  const [numberOfSemesters, setNumberOfSemesters] = useState(0); // Number of semesters

  const [showSemesterModal, setShowSemesterModal] = useState(false); // To toggle modal visibility
  const [calculatedSemesters, setCalculatedSemesters] = useState([]); // To store the calculated semester details

  const [numberOfMonths, setNumberOfMonths] = useState(0); // State for number of months

  useEffect(() => {
    if (batchStartDate && numberOfMonths > 0) {
      const startDate = new Date(batchStartDate);
      const calculatedEndDate = new Date(startDate);
      calculatedEndDate.setMonth(
        startDate.getMonth() + parseInt(numberOfMonths)
      ); // Add months to start date
      setBatchEndDate(calculatedEndDate.toISOString().split("T")[0]); // Update end date
    }
  }, [batchStartDate, numberOfMonths]); // Recalculate when start date or months change

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    await fetchBatches();
    await fetchAllStaff();
    await fetchAllSubjects();
  };

  function getStatusBadge(status) {
    switch (status) {
      case "not started":
        return <Badge bg="secondary">Not Started</Badge>;
      case "ongoing":
        return <Badge bg="primary">Ongoing</Badge>;
      case "completed":
        return <Badge bg="success">Completed</Badge>;
      default:
        return <Badge bg="light">N/A</Badge>;
    }
  }

  const fetchBatches = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/batches-with-courses");
      setBatches(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    if (!dateString) {
      return "N/A";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const fetchCourseSubjects = async (batchId) => {
    setLoadingSubject(true);
    try {
      const response = await axiosInstance.get(`/batch/${batchId}/subjects`);
      setCourseSubjects(response.data);
    } catch (error) {
      handleErrorWith404(error, setCourseSubjects);
    } finally {
      setLoadingSubject(false);
    }
  };

 const handleShowSemesterModal = () => {
   if (batchStartDate && batchEndDate && numberOfSemesters > 0) {
     const startDate = new Date(batchStartDate);
     const endDate = new Date(batchEndDate);
     const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24); // Total days between start and end date
     const daysPerSemester = totalDays / numberOfSemesters;

     const calculatedDates = [];

     for (let i = 0; i < numberOfSemesters; i++) {
       const semesterStartDate = new Date(startDate);
       semesterStartDate.setDate(startDate.getDate() + i * daysPerSemester);

       const semesterEndDate = new Date(semesterStartDate);
       semesterEndDate.setDate(semesterStartDate.getDate() + daysPerSemester);

       const assignmentReleaseDate = new Date(semesterStartDate);
       assignmentReleaseDate.setMonth(assignmentReleaseDate.getMonth() + 1);

       const assignmentSubmissionDate = new Date(semesterEndDate);
       assignmentSubmissionDate.setDate(
         assignmentSubmissionDate.getDate() + 20
       );

       const assignmentLateSubmissionDate = new Date(assignmentSubmissionDate);
       assignmentLateSubmissionDate.setDate(
         assignmentLateSubmissionDate.getDate() + 14
       );

       // Calculate the exact number of months between the start and end date of the semester
       const timeDiff = semesterEndDate - semesterStartDate;
       const monthsBetween = timeDiff / (1000 * 60 * 60 * 24 * 30.436875); // Convert time difference to months

       calculatedDates.push({
         semester: i + 1,
         startDate: semesterStartDate.toISOString().split("T")[0],
         endDate: semesterEndDate.toISOString().split("T")[0],
         AssignmentReleaseDate: assignmentReleaseDate
           .toISOString()
           .split("T")[0],
         AssignmentSubmissionDate: assignmentSubmissionDate
           .toISOString()
           .split("T")[0],
         AssignmentLateSubmissionDate: assignmentLateSubmissionDate
           .toISOString()
           .split("T")[0],
         daysBetween: Math.floor(daysPerSemester),
         monthsBetween: monthsBetween.toFixed(1), // Show the number of months with two decimal places
       });
     }

     setCalculatedSemesters(calculatedDates); // Store the calculated data
     setShowSemesterModal(true); // Show modal
   } else {
     toast.error(
       "Please enter valid batch start date, number of months, and number of semesters."
     );
   }
 };




  const fetchAssignedSubjects = async (batchId) => {
    setLoadingSubject(true);
    try {
      const response = await axiosInstance.get(
        `/batch-subjects/batch/${batchId}`
      );
      setAssignedSubjects(response.data);
    } catch (error) {
      handleErrorWith404(error, setAssignedSubjects);
    } finally {
      setLoadingSubject(false);
    }
  };

  const fetchAllStaff = async () => {
    try {
      const response = await axiosInstance.get("/staff");
      const staffOptions = response.data.map((staff) => ({
        value: staff.staffId,
        label: staff.name,
      }));
      setStaffList(staffOptions);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchAllSubjects = async () => {
    try {
      const response = await axiosInstance.get("/subjects");
      setAllSubjects(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleShowSubjects = (batch) => {
    setCurrentBatch(batch);
    fetchAssignedSubjects(batch.batchId);
    setShowSubjectModal(true);
  };

  const handleShowSubjectsAssign = (batch) => {
    setCurrentBatch(batch);
    setSubjectAssignments([]); // Reset subject assignments when opening modal
    fetchCourseSubjects(batch.batchId);
    setShowSubjectAssignModal(true);
  };

  const validateAssignments = () => {
    const errors = [];

    if (!currentBatch || !currentBatch.batchId) {
      errors.push("Batch ID is required.");
    }

    if (subjectAssignments.length === 0) {
      errors.push("At least one subject must be selected for assignment.");
    }

    subjectAssignments.forEach((assignment) => {
      validateAssignmentFields(assignment, errors);
    });

    return errors;
  };

  const validateAssignmentFields = (assignment, errors) => {
    // Required fields validation (fields that cannot be null)
    if (!assignment.startDate) {
      errors.push(
        `Start date is required for subject ${assignment.courseSubjectId}.`
      );
    }
    if (!assignment.endDate) {
      errors.push(
        `End date is required for subject ${assignment.courseSubjectId}.`
      );
    }
    if (new Date(assignment.endDate) < new Date(assignment.startDate)) {
      errors.push(
        `End date must be later than the start date for subject ${assignment.courseSubjectId}.`
      );
    }
    if (!assignment.status) {
      errors.push(
        `Status is required for subject ${assignment.courseSubjectId}.`
      );
    }
    if (!assignment.semester) {
      errors.push(
        `Semester is required for subject ${assignment.courseSubjectId}.`
      );
    }

    // Optional fields validation (fields that can be null)
    if (
      assignment.expectedNumberOfClasses !== null &&
      assignment.expectedNumberOfClasses <= -1
    ) {
      errors.push(
        `Expected number of classes must be a positive number for subject ${assignment.courseSubjectId}.`
      );
    }

    // Conditional validation for assignment dates if they are provided
    if (
      assignment.AssignmentSubmissionDate &&
      assignment.AssignmentReleaseDate &&
      new Date(assignment.AssignmentSubmissionDate) <
        new Date(assignment.AssignmentReleaseDate)
    ) {
      errors.push(
        `Assignment submission date must be later than the release date for subject ${assignment.courseSubjectId}.`
      );
    }

    if (
      assignment.AssignmentLateSubmissionDate &&
      assignment.AssignmentSubmissionDate &&
      new Date(assignment.AssignmentLateSubmissionDate) <
        new Date(assignment.AssignmentSubmissionDate)
    ) {
      errors.push(
        `Assignment late submission date must be later than the submission date for subject ${assignment.courseSubjectId}.`
      );
    }
  };

  const handleDeleteSubject = async (courseSubjectId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/batch-subject/${courseSubjectId}`);
          toast.success("Subject deleted successfully!");
          fetchAssignedSubjects(currentBatch.batchId);

          Swal.fire("Deleted!", "Your subject has been deleted.", "success");
        } catch (error) {
          handleError(error);
          Swal.fire(
            "Error!",
            "There was an error deleting the subject.",
            "error"
          );
        }
      }
    });
  };

  const handleSaveAssignments = async () => {
    const errors = validateAssignments();
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    try {
      // Construct the subjects array with individual subject data
      const subjects = subjectAssignments.map((assignment) => ({
        courseSubjectId: assignment.courseSubjectId,
        staffId: assignment.staffId,
        startDate: assignment.startDate,
        endDate: assignment.endDate,
        status: assignment.status,
        semester: assignment.semester,
        expectedNumberOfClasses: assignment.expectedNumberOfClasses,
        AssignmentReleaseDate: assignment.AssignmentReleaseDate,
        AssignmentSubmissionDate: assignment.AssignmentSubmissionDate,
        AssignmentLateSubmissionDate: assignment.AssignmentLateSubmissionDate,
      }));

      // Send the request to the backend with batchId and the array of subjects
      await axiosInstance.post("/batch-subjects/bulk", {
        batchId: currentBatch.batchId, // Batch ID
        subjects, // Array of subject objects
      });

      toast.success("Subjects assigned successfully!");
      setShowSubjectAssignModal(false);
      fetchBatches();
      fetchAssignedSubjects(currentBatch.batchId);
    } catch (error) {
      handleError(error);
    }
  };

  const [semesterDates, setSemesterDates] = useState([]); // Store calculated semester dates

const handleSemesterChange = (semesterCount) => {
  setNumberOfSemesters(semesterCount);

  if (batchStartDate && batchEndDate && semesterCount > 0) {
    const startDate = new Date(batchStartDate);
    const endDate = new Date(batchEndDate);
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24); // Calculate total days between start and end
    const daysPerSemester = totalDays / semesterCount;

    const calculatedDates = [];

    for (let i = 0; i < semesterCount; i++) {
      const semesterStartDate = new Date(startDate);
      semesterStartDate.setDate(startDate.getDate() + i * daysPerSemester);

      const semesterEndDate = new Date(semesterStartDate);
      semesterEndDate.setDate(semesterStartDate.getDate() + daysPerSemester);

      const assignmentReleaseDate = new Date(semesterStartDate);
      assignmentReleaseDate.setMonth(assignmentReleaseDate.getMonth() + 1);

      const assignmentSubmissionDate = new Date(semesterEndDate);
      assignmentSubmissionDate.setDate(assignmentSubmissionDate.getDate() + 20);

      const assignmentLateSubmissionDate = new Date(assignmentSubmissionDate);
      assignmentLateSubmissionDate.setDate(
        assignmentLateSubmissionDate.getDate() + 14
      );

      calculatedDates.push({
        semester: i + 1,
        startDate: semesterStartDate.toISOString().split("T")[0],
        endDate: semesterEndDate.toISOString().split("T")[0],
        AssignmentReleaseDate: assignmentReleaseDate
          .toISOString()
          .split("T")[0],
        AssignmentSubmissionDate: assignmentSubmissionDate
          .toISOString()
          .split("T")[0],
        AssignmentLateSubmissionDate: assignmentLateSubmissionDate
          .toISOString()
          .split("T")[0],
        daysBetween: Math.floor(daysPerSemester),
      });
    }

    setCalculatedSemesters(calculatedDates); // Store the calculated data
  } else {
    toast.error(
      "Please enter valid batch start date, number of months, and number of semesters."
    );
  }
};


  const handleSubjectChange = (courseSubjectId, field, value) => {
    setSubjectAssignments((prev) =>
      prev.some((assignment) => assignment.courseSubjectId === courseSubjectId)
        ? prev.map((assignment) =>
            assignment.courseSubjectId === courseSubjectId
              ? {
                  ...assignment,
                  [field]: value,
                  // If the semester is being updated, calculate and update the dates
                  ...(field === "semester" && value
                    ? (() => {
                        const semesterNumber = Number(value);
                        const semesterData = calculatedSemesters.find(
                          (sem) => sem.semester === semesterNumber
                        );
                        if (semesterData) {
                          return {
                            startDate: semesterData.startDate,
                            endDate: semesterData.endDate,
                            AssignmentReleaseDate:
                              semesterData.AssignmentReleaseDate,
                            AssignmentSubmissionDate:
                              semesterData.AssignmentSubmissionDate,
                            AssignmentLateSubmissionDate:
                              semesterData.AssignmentLateSubmissionDate,
                          };
                        }
                        return {};
                      })()
                    : {}),
                }
              : assignment
          )
        : [
            ...prev,
            {
              courseSubjectId,
              staffId: null,
              startDate: "",
              endDate: "",
              status: "not started",
              expectedNumberOfClasses: 0,
              semester: "",
              AssignmentReleaseDate: "",
              AssignmentSubmissionDate: "",
              AssignmentLateSubmissionDate: "",
              [field]: value,
              // Automatically populate dates if semester is input
              ...(field === "semester" && value
                ? (() => {
                    const semesterNumber = Number(value);
                    const semesterData = calculatedSemesters.find(
                      (sem) => sem.semester === semesterNumber
                    );
                    if (semesterData) {
                      return {
                        startDate: semesterData.startDate,
                        endDate: semesterData.endDate,
                        AssignmentReleaseDate:
                          semesterData.AssignmentReleaseDate,
                        AssignmentSubmissionDate:
                          semesterData.AssignmentSubmissionDate,
                        AssignmentLateSubmissionDate:
                          semesterData.AssignmentLateSubmissionDate,
                      };
                    }
                    return {};
                  })()
                : {}),
            },
          ]
    );
  };


  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
  };

  const handleErrorWith404 = (error, setter) => {
    if (error.response && error.response.status === 404) {
      setter([]);
    } else {
      handleError(error);
    }
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleSubjectSearch = (e) => setSearchSubjectTerm(e.target.value);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredBatches = batches.filter((batch) =>
    Object.values(batch)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentBatches = filteredBatches.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredSubjects = assignedSubjects.filter((subject) =>
    Object.values(subject)
      .join(" ")
      .toLowerCase()
      .includes(searchSubjectTerm.toLowerCase())
  );

  const filteredCourseSubjects = courseSubjects.filter((subject) =>
    Object.values(subject)
      .join(" ")
      .toLowerCase()
      .includes(searchSubjectTerm.toLowerCase())
  );

  if (loading) return <Loader />;

  return (
    <AcademicLayout>
      <h1>Batch List</h1>
      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Batch Name</th>
            <th>Count</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentBatches.length === 0 ? (
            <tr>
              <td colSpan="4">No batches found</td>
            </tr>
          ) : (
            currentBatches.map((batch) => (
              <tr key={batch.batchId}>
                <td>{batch.batchId}</td>
                <td>
                  {batch.batchName} - {batch.scheduleType}
                </td>
                <td>
                  {batch.subjectCount === 0 ? (
                    <Badge bg="danger">{batch.subjectCount}</Badge>
                  ) : batch.subjectCount < 16 ? (
                    <Badge bg="warning">{batch.subjectCount}</Badge>
                  ) : (
                    <Badge bg="success">{batch.subjectCount}</Badge>
                  )}
                </td>
                <td>
                  <Button
                    variant="info"
                    className="me-2"
                    size="sm"
                    onClick={() => handleShowSubjects(batch)}
                  >
                    Show Assigned Subjects
                  </Button>
                  <Button
                    variant="primary"
                    className="me-2"
                    size="sm"
                    onClick={() => handleShowSubjectsAssign(batch)}
                  >
                    Assign Subjects
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredBatches.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {/* Modal to show assigned subjects */}
      <Modal
        show={showSubjectModal}
        onHide={() => setShowSubjectModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentBatch
              ? `Assigned Subjects - Batch: ${currentBatch.batchName} - ${currentBatch.scheduleType}`
              : "Assigned Subjects"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {currentBatch &&
                `Batch Start Date : ${formatDate(
                  currentBatch.batchStartingDate
                )}`}
              <hr />
            </Col>
          </Row>
          <InputGroup>
            <FormControl
              placeholder="Search Subjects"
              value={searchSubjectTerm}
              onChange={handleSubjectSearch}
            />
          </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Batch Subject ID</th> {/* bs.batchSubjectId */}
                <th>Subject Name</th> {/* s.fullName as subjectName */}
                <th>Staff</th> <th>Start Date</th> {/* bs.startDate */}
                <th>End Date</th> {/* bs.endDate */}
                <th>Status</th> {/* bs.status */}
                <th>Expected Number of Classes</th> <th>Semester</th>{" "}
                {/* bs.semester */}
                <th>Assignment Release Date</th>{" "}
                <th>Assignment Submission Date</th>{" "}
                <th>Assignment Late Submission Date</th> <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubjects.length === 0 ? (
                <tr>
                  <td colSpan="12">No subjects assigned</td>
                </tr>
              ) : (
                filteredSubjects.map((subject) => (
                  <tr key={subject.batchSubjectId}>
                    <td>{subject.batchSubjectId}</td>
                    <td>{subject.subjectName || "N/A"}</td>
                    <td>{subject.staffName || "N/A"}</td>
                    <td>{formatDate(subject.startDate)}</td>{" "}
                    <td>{formatDate(subject.endDate)}</td>{" "}
                    <td>{getStatusBadge(subject.status)}</td>{" "}
                    <td>{subject.expectedNumberOfClasses || "N/A"}</td>
                    <td>{subject.semester || "N/A"}</td>
                    <td>{formatDate(subject.AssignmentReleaseDate)}</td>{" "}
                    <td>{formatDate(subject.AssignmentSubmissionDate)}</td>{" "}
                    <td>{formatDate(subject.AssignmentLateSubmissionDate)}</td>{" "}
                    <td>
                      {" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() =>
                          handleDeleteSubject(subject.batchSubjectId)
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSubjectModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to assign subjects */}
      <Modal
        show={showSubjectAssignModal}
        onHide={() => setShowSubjectAssignModal(false)}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentBatch
              ? `Assigned Subjects - Batch: ${currentBatch.batchName} - ${currentBatch.scheduleType}`
              : "Assigned Subjects"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {currentBatch &&
                `Batch Start Date : ${formatDate(
                  currentBatch.batchStartingDate
                )}`}

              <hr />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={3}>
              <Form.Label>Batch Start Date</Form.Label>
              <Form.Control
                type="date"
                value={batchStartDate}
                onChange={(e) => setBatchStartDate(e.target.value)}
              />
            </Col>
            <Col xs={2}>
              <Form.Label>Number of Months</Form.Label>
              <Form.Control
                type="number"
                value={numberOfMonths}
                onChange={(e) => setNumberOfMonths(e.target.value)} // Store the number of months
              />
            </Col>
            <Col xs={3}>
              <Form.Label>Batch End Date</Form.Label>
              <Form.Control
                type="date"
                value={batchEndDate}
                readOnly // Make this field read-only, as it's calculated automatically
              />
            </Col>
            <Col xs={2}>
              <Form.Label>Number of Semesters</Form.Label>
              <Form.Control
                type="number"
                value={numberOfSemesters}
                onChange={(e) => handleSemesterChange(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                variant="info"
                className="mt-4"
                onClick={handleShowSemesterModal}
              >
                Show Semester Calculation
              </Button>
            </Col>
          </Row>

          <InputGroup>
            <FormControl
              placeholder="Search Subjects"
              value={searchSubjectTerm}
              onChange={handleSubjectSearch}
            />
          </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>ID</th>
                <th>Subject Name</th>
                <th>Staff</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Expected Number of Classes</th>
                <th>Semester</th>
                <th>Assignment Release Date</th>
                <th>Assignment Submission Date</th>
                <th>Assignment Late Submission Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredCourseSubjects.length === 0 ? (
                <tr>
                  <td colSpan="11">No subjects found</td>
                </tr>
              ) : (
                filteredCourseSubjects.map((subject) => (
                  <tr key={subject.courseSubjectId}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={subjectAssignments.some(
                          (assignment) =>
                            assignment.courseSubjectId ===
                            subject.courseSubjectId
                        )}
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "courseSubjectId",
                            e.target.checked
                          )
                        }
                      />
                    </td>
                    <td>{subject.courseSubjectId}</td>
                    <td>{subject.shortName}</td>
                    <td>
                      <ReactSelect
                        options={staffList}
                        onChange={(selected) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "staffId",
                            selected ? selected.value : null
                          )
                        }
                        defaultValue={staffList.find(
                          (staff) =>
                            staff.value ===
                            subjectAssignments.find(
                              (assignment) =>
                                assignment.courseSubjectId ===
                                subject.courseSubjectId
                            )?.staffId
                        )}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.startDate || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "startDate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.endDate || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "endDate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <FormSelect
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.status || "not started"
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "status",
                            e.target.value
                          )
                        }
                      >
                        <option value="not started">Not Started</option>
                        <option value="in progress">In Progress</option>
                        <option value="completed">Completed</option>
                      </FormSelect>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.expectedNumberOfClasses || 0
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "expectedNumberOfClasses",
                            Number(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.semester || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "semester",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.AssignmentReleaseDate || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "AssignmentReleaseDate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.AssignmentSubmissionDate || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "AssignmentSubmissionDate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={
                          subjectAssignments.find(
                            (assignment) =>
                              assignment.courseSubjectId ===
                              subject.courseSubjectId
                          )?.AssignmentLateSubmissionDate || ""
                        }
                        onChange={(e) =>
                          handleSubjectChange(
                            subject.courseSubjectId,
                            "AssignmentLateSubmissionDate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSaveAssignments}
            disabled={subjectAssignments.length === 0}
          >
            Save Assignments
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowSubjectAssignModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSemesterModal}
        onHide={() => setShowSemesterModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Semester Calculation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {calculatedSemesters.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Semester</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Release Date</th>
                  <th>Submission Date</th>
                  <th>Late Submission Date</th>
                  <th>Days Between</th>
                  <th>Months Between</th> {/* New column for months */}
                </tr>
              </thead>
              <tbody>
                {calculatedSemesters.map((sem) => (
                  <tr key={sem.semester}>
                    <td>{sem.semester}</td>
                    <td>{sem.startDate}</td>
                    <td>{sem.endDate}</td>
                    <td>{sem.AssignmentReleaseDate}</td>
                    <td>{sem.AssignmentSubmissionDate}</td>
                    <td>{sem.AssignmentLateSubmissionDate}</td>
                    <td>{sem.daysBetween}</td>
                    <td>{sem.monthsBetween}</td> {/* Display the months */}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSemesterModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </AcademicLayout>
  );
}

export default BatchSubjects;
