import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../utils/axiosInstance";
import Select from "react-select"; // Import react-select

function BatchGroupBatches({ groupId, onClose }) {
  const [batches, setBatches] = useState([]);
  const [allBatches, setAllBatches] = useState([]); // For adding new batches
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBatchesForGroup();
    fetchAllBatches(); // Get all available batches
  }, [groupId]);

  // Fetch batches linked to the batch group
  const fetchBatchesForGroup = async () => {
    try {
      const response = await axiosInstance.get(
        `/batch-group/${groupId}/batches`
      );
      setBatches(response.data);
    } catch (error) {
      console.error("Error fetching batches:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all available batches to display in the modal for selection
  const fetchAllBatches = async () => {
    try {
      const response = await axiosInstance.get("/batches");
      setAllBatches(
        response.data.map((batch) => ({
          value: batch.batchId,
          label: batch.batchName,
        }))
      ); // Map batches to the format react-select expects
    } catch (error) {
      console.error("Error fetching all batches:", error);
    }
  };

  // Add batch to group
  const handleAddBatch = async () => {
    try {
      await axiosInstance.post(`/batch-group/${groupId}/add-batch`, {
        batchId: selectedBatch.value, // Use the value of the selected option
      });
      fetchBatchesForGroup(); // Refresh the list of linked batches
      setSelectedBatch(null); // Reset selected batch
    } catch (error) {
      console.error("Error adding batch:", error);
    }
  };

  // Remove batch from group
  const handleDeleteBatch = async (batchId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(
            `/batch-group/${groupId}/remove-batch/${batchId}`
          );
          Swal.fire("Deleted!", "Batch has been removed.", "success");
          fetchBatchesForGroup(); // Refresh after deletion
        } catch (error) {
          console.error("Error deleting batch:", error);
        }
      }
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h3>Assigned Batches</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Batch ID</th>
            <th>Batch Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {batches.length === 0 ? (
            <tr>
              <td colSpan="3">No batches assigned</td>
            </tr>
          ) : (
            batches.map((batch) => (
              <tr key={batch.batchId}>
                <td>{batch.batchId}</td>
                <td>{batch.batchName}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteBatch(batch.batchId)}
                    size="sm"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <div>
        <label>Select Batch</label>
        <Select
          value={selectedBatch}
          onChange={setSelectedBatch}
          options={allBatches}
          placeholder="Select a batch"
          isClearable
        />
      </div>

      <Button
        variant="primary"
        className="mt-3"
        onClick={handleAddBatch}
        disabled={!selectedBatch}
      >
        Add Batch
      </Button>

      <Button variant="secondary" className="mt-3 ms-2" onClick={onClose}>
        Close
      </Button>
    </div>
  );
}

export default BatchGroupBatches;
