// src/services/authService.js

class AuthService {
  // Store user information and API key in localStorage
  static setUserInfo(superAdmin, apiKey) {
    const userInfo = {
      superAdmin,
      apiKey,
    };
    localStorage.setItem("superAdminInfo", JSON.stringify(userInfo));
  }

  // Retrieve user information and API key from localStorage
  static getUserInfo() {
    const userInfo = localStorage.getItem("superAdminInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  }

  // Get the API key specifically from localStorage
  static getApiKey() {
    const userInfo = this.getUserInfo();
    return userInfo ? userInfo.apiKey : null;
  }

  // Clear user information and API key from localStorage
  static clearAuth() {
    localStorage.removeItem("superAdminInfo");
  }
}

export default AuthService;
