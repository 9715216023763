import AcademicLayout from "../Layout/AcademicLayout";

function Academic() {
  return (
    <AcademicLayout>
      <h1>Academic</h1>
    </AcademicLayout>
  );
}

export default Academic;
