import NotepadLayout from "../Layout/NotepadLayout";

function Notepad() {
    return (
      <NotepadLayout>
        <h1>Notepad</h1>
      </NotepadLayout>
    );
}

export default Notepad;