import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination"; // Assuming you have a Pagination component

function Staffs() {
  const [staff, setStaff] = useState([]);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    employeeNumber: "",
    name: "",
    officeEmail: "",
    gmail: "",
    positionId: "",
    password: "",
    image: null,
    gender: "", // Added gender field
    title: "", // Added title field
  });

  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingStaff, setEditingStaff] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [imagePreview, setImagePreview] = useState(null);

  const [mobileModalVisible, setMobileModalVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileId, setMobileId] = useState(null);

  // New states for managing roster
  const [rosterModalVisible, setRosterModalVisible] = useState(false);
  const [selectedStaffForRoster, setSelectedStaffForRoster] = useState(null);
  const [roster, setRoster] = useState([]);
  const [rosterDay, setRosterDay] = useState("");
  const [dayType, setDayType] = useState("");
  const [rosterId, setRosterId] = useState(null);

  // Function to handle opening the mobile management modal and fetching mobile numbers
  const handleManageMobile = async (staff) => {
    setSelectedStaff(staff);
    setMobileModalVisible(true);
    fetchMobileNumbers(staff.staffId); // Fetch mobile numbers for the selected staff
  };

  // Function to handle opening the roster management modal and fetching roster data
  const handleManageRoster = async (staff) => {
    setSelectedStaffForRoster(staff);
    setRosterModalVisible(true);
    fetchRoster(staff.staffId); // Fetch roster entries for the selected staff
  };

  // Function to fetch the existing roster entries for the selected staff
  const fetchRoster = async (staffId) => {
    try {
      const response = await axiosInstance.get(`/roster/staff/${staffId}`);
      setRoster(response.data); // Set the roster entries in the state
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setRoster([]); // Clear roster, if none exist
      } else {
        handleError(error);
      }
    }
  };

  // Function to handle submitting the roster form (insert or update)
  const handleRosterSubmit = async () => {
    if (rosterId) {
      // Update existing roster entry
      try {
        await axiosInstance.put(`/roster/${rosterId}`, {
          staffId: selectedStaffForRoster.staffId,
          rosterDay,
          dayType,
        });
        setRoster((prevRoster) =>
          prevRoster.map((entry) =>
            entry.rosterId === rosterId
              ? { ...entry, rosterDay, dayType }
              : entry
          )
        );
        toast.success("Roster updated successfully");
        setRosterId(null);
        setRosterDay("");
        setDayType("");
      } catch (error) {
        handleError(error);
      }
    } else {
      // Insert new roster entry
      try {
        const response = await axiosInstance.post("/roster", {
          staffId: selectedStaffForRoster.staffId,
          rosterDay,
          dayType,
        });
        setRoster((prevRoster) => [
          ...prevRoster,
          { rosterId: response.data.rosterId, rosterDay, dayType },
        ]);
        toast.success("Roster added successfully");
        setRosterDay("");
        setDayType("");
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Function to handle deleting a roster entry
  const handleDeleteRoster = async (rosterId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/roster/${rosterId}`);
        setRoster((prevRoster) =>
          prevRoster.filter((entry) => entry.rosterId !== rosterId)
        );
        Swal.fire("Deleted!", "Roster entry has been deleted.", "success");
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Function to fetch the existing mobile numbers for the selected staff
  const fetchMobileNumbers = async (staffId) => {
    try {
      const response = await axiosInstance.get(
        `/staff-mobile/staff/${staffId}`
      );
      setMobileNumbers(response.data); // Set the mobile numbers in the state
    } catch (error) {
      // Check if the error is a 404 and contains the specific message
      if (error.response && error.response.status === 404) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "No mobile numbers found for this staff member") {
          setMobileNumbers([]); // Clear mobile numbers, if none exist
        } else {
          handleError(error); // For other 404 errors, handle normally
        }
      } else {
        handleError(error); // Handle other types of errors (non-404)
      }
    }
  };

  // Function to handle submitting the mobile number form (insert or update)
  const handleMobileSubmit = async () => {
    if (mobileId) {
      // Show SweetAlert2 confirmation for update
      const result = await Swal.fire({
        title: "Update Mobile Number",
        text: "Are you sure you want to update this mobile number?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      });

      if (result.isConfirmed) {
        try {
          // Update existing mobile number
          await axiosInstance.put(`/staff-mobile/${mobileId}`, {
            staffId: selectedStaff.staffId,
            mobileNumber,
          });
          // Update the mobileNumbers state after successful update
          setMobileNumbers((prevMobileNumbers) =>
            prevMobileNumbers.map((mobile) =>
              mobile.staffMobileId === mobileId
                ? { ...mobile, mobileNumber }
                : mobile
            )
          );
          toast.success("Mobile number updated successfully");
          // Reset form and state after update
          setMobileId(null);
          setMobileNumber("");
        } catch (error) {
          handleError(error);
        }
      }
    } else {
      // No confirmation for insert; directly insert the new mobile number
      try {
        const response = await axiosInstance.post("/staff-mobile", {
          staffId: selectedStaff.staffId,
          mobileNumber,
        });
        // Add the new mobile number to the mobileNumbers state
        setMobileNumbers((prevMobileNumbers) => [
          ...prevMobileNumbers,
          { staffMobileId: response.data.staffMobileId, mobileNumber },
        ]);
        toast.success("Mobile number added successfully");
        // Reset form and state after insert
        setMobileNumber("");
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Function to handle deleting a mobile number
  const handleDeleteMobileNumber = async (mobileId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/staff-mobile/${mobileId}`);
        // Remove the deleted mobile number from the mobileNumbers state
        setMobileNumbers((prevMobileNumbers) =>
          prevMobileNumbers.filter(
            (mobile) => mobile.staffMobileId !== mobileId
          )
        );
        Swal.fire("Deleted!", "Mobile number has been deleted.", "success");
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Schema for Joi validation
  // Schema for Joi validation
  const schema = Joi.object({
    employeeNumber: Joi.string().allow(null, "").label("Employee Number"),
    name: Joi.string().required().label("Name"),
    officeEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .label("Office Email"),
    gmail: Joi.string()
      .regex(/^[\w-\.]+@gmail\.com$/)
      .label("Gmail")
      .allow(null, ""),
    password: Joi.string().min(5).optional().allow(null, ""),
    positionId: Joi.alternatives()
      .try(Joi.string(), Joi.number())
      .required()
      .label("Position ID"), // Accepts string or number
    gender: Joi.string().valid("male", "female").required().label("Gender"), // Added gender validation
    title: Joi.string()
      .valid("Mr", "Mrs", "Miss", "Ms", "Dr")
      .required()
      .label("Title"), // Added title validation
    image: Joi.any().optional().allow(null, ""),
  });

  useEffect(() => {
    fetchStaff();
    fetchPositions();
  }, []);

  const fetchStaff = async () => {
    try {
      const response = await axiosInstance.get("/staff");
      setStaff(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPositions = async () => {
    try {
      const response = await axiosInstance.get("/positions");
      setPositions(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
    console.log(message);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      setImagePreview(URL.createObjectURL(file)); // Preview the selected image
    } else {
      setImagePreview(null); // Reset if no file is selected
    }
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page
    const validationErrors = validateForm();
    setErrors(validationErrors || {});

    if (validationErrors) return;

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      if (editingStaff) {
        // Update existing staff member
        await axiosInstance.put(
          `/staff/${editingStaff.staffId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Staff member updated successfully!");
      } else {
        // Create new staff member
        await axiosInstance.post("/staff", formDataToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Staff member created successfully!");
      }

      fetchStaff(); // Refresh staff list
      setShowModal(false); // Close the modal after submission
      setFormData({
        employeeNumber: "",
        name: "",
        officeEmail: "",
        gmail: "",
        positionId: "",
        password: "",
        image: null,
      });
      setImagePreview(null);
      setEditingStaff(null);
    } catch (error) {
      console.error("Error in API request:", error);
      handleError(error);
    }
  };

  const handleEdit = (staff) => {
    setEditingStaff(staff);

    // Set form data with existing staff details
    setFormData({
      employeeNumber: staff.employeeNumber || "",
      name: staff.name || "",
      officeEmail: staff.officeEmail || "",
      gmail: staff.gmail || "",
      positionId: staff.positionId || "",
      password: "", // Leave password empty for editing
      gender: staff.gender || "", // Set gender for editing
      title: staff.title || "", // Set title for editing
      image: null,
    });

    // Conditionally set image preview if staff has an imagePath
    const imagePath = staff.imagePath
      ? `https://api.kaaryaalaya.com/api${staff.imagePath}`
      : null;
    setImagePreview(imagePath);

    // Open the modal for editing
    setShowModal(true);
  };

  const handleDelete = (staffId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/staff/${staffId}`);
          Swal.fire("Deleted!", "Staff member has been deleted.", "success");
          fetchStaff();
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredStaff = staff.filter((staffMember) =>
    Object.values(staffMember)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentStaff = filteredStaff.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loader />;
  }

  return (
    <SettingLayout>
      <h1>Staff</h1>

      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setShowModal(true)}
      >
        {editingStaff ? "Edit Staff" : "Add Staff"}
      </Button>

      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Employee Number</th>
            <th>Name</th>
            <th>Office Email</th>
            <th>Position</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentStaff.length === 0 ? (
            <tr>
              <td colSpan="7">No staff found</td>
            </tr>
          ) : (
            currentStaff.map((staffMember) => (
              <tr key={staffMember.staffId}>
                <td>{staffMember.staffId}</td>
                <td>{staffMember.employeeNumber}</td>
                <td>{staffMember.name}</td>
                <td>{staffMember.officeEmail}</td>
                <td>
                  {positions.find(
                    (position) => position.positionId === staffMember.positionId
                  )?.positionName || "N/A"}
                </td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-2"
                    onClick={() => handleManageMobile(staffMember)}
                  >
                    Manage Mobile
                  </Button>
                  <Button
                    variant="info"
                    size="sm"
                    className="me-2"
                    onClick={() => handleManageRoster(staffMember)}
                  >
                    Manage Roster
                  </Button>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(staffMember)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(staffMember.staffId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredStaff.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingStaff ? "Edit Staff" : "Add New Staff"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="employeeNumber">
                  <Form.Label>Employee Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="employeeNumber"
                    value={formData.employeeNumber}
                    onChange={handleInputChange}
                    isInvalid={!!errors.employeeNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.employeeNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={8}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="officeEmail" className="mt-3">
              <Form.Label>Office Email</Form.Label>
              <Form.Control
                type="email"
                name="officeEmail"
                value={formData.officeEmail}
                onChange={handleInputChange}
                isInvalid={!!errors.officeEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.officeEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="gmail" className="mt-3">
              <Form.Label>Gmail</Form.Label>
              <Form.Control
                type="email"
                name="gmail"
                value={formData.gmail}
                onChange={handleInputChange}
                isInvalid={!!errors.gmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="positionId" className="mt-3">
              <Form.Label>Position</Form.Label>
              <Form.Select
                name="positionId"
                value={formData.positionId}
                onChange={handleInputChange}
                isInvalid={!!errors.positionId}
              >
                <option value="">Select Position</option>
                {positions.map((position) => (
                  <option key={position.positionId} value={position.positionId}>
                    {position.positionName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.positionId}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group controlId="password" className="mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="gender" className="mt-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="title" className="mt-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Select
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    isInvalid={!!errors.title}
                  >
                    <option value="">Select Title</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="image" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Selected"
                  className="mt-3"
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Staff
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={mobileModalVisible}
        onHide={() => setMobileModalVisible(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Manage Mobile Numbers for {selectedStaff?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="mobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              className="mt-3"
              onClick={handleMobileSubmit}
              disabled={!mobileNumber}
            >
              {mobileId ? "Update Mobile Number" : "Add Mobile Number"}
            </Button>
          </Form>

          {/* Existing Mobile Numbers Table */}
          {mobileNumbers.length > 0 && (
            <>
              <h5 className="mt-4">Existing Mobile Numbers</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Mobile Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mobileNumbers.map((mobile) => (
                    <tr key={mobile.staffMobileId}>
                      <td>{mobile.mobileNumber}</td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() => {
                            setMobileNumber(mobile.mobileNumber);
                            setMobileId(mobile.staffMobileId);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            handleDeleteMobileNumber(mobile.staffMobileId)
                          }
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setMobileModalVisible(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Roster Management Modal */}
      <Modal
        show={rosterModalVisible}
        onHide={() => setRosterModalVisible(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Manage Roster for {selectedStaffForRoster?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="rosterDay">
              <Form.Label>Roster Day</Form.Label>
              <Form.Select
                value={rosterDay}
                onChange={(e) => setRosterDay(e.target.value)}
              >
                <option value="">Select Day</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="dayType" className="mt-3">
              <Form.Label>Day Type</Form.Label>
              <Form.Select
                value={dayType}
                onChange={(e) => setDayType(e.target.value)}
              >
                <option value="">Select Day Type</option>
                <option value="morning half day">Morning Half Day</option>
                <option value="evening half day">Evening Half Day</option>
                <option value="off day">Off Day</option>
              </Form.Select>
            </Form.Group>

            <Button
              variant="primary"
              className="mt-3"
              onClick={handleRosterSubmit}
              disabled={!rosterDay || !dayType}
            >
              {rosterId ? "Update Roster" : "Add Roster"}
            </Button>
          </Form>

          {/* Existing Roster Table */}
          {roster.length > 0 && (
            <>
              <h5 className="mt-4">Existing Roster</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Roster Day</th>
                    <th>Day Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {roster.map((entry) => (
                    <tr key={entry.rosterId}>
                      <td>{entry.rosterDay}</td>
                      <td>{entry.dayType}</td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() => {
                            setRosterDay(entry.rosterDay);
                            setDayType(entry.dayType);
                            setRosterId(entry.rosterId);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteRoster(entry.rosterId)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setRosterModalVisible(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </SettingLayout>
  );
}

export default Staffs;
