import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
  Badge,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination"; // Assuming you have a Pagination component
import BatchGroupBatches from "./BatchGroupBatches";

function BatchGroups() {
  const [batchGroups, setBatchGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showBatchGroupBatchesModal, setShowBatchGroupBatchesModal] =
    useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedGroupWhatsAppName, setSelectedGroupWhatsAppName] = useState(null);
  const [formData, setFormData] = useState({ name: "", whatsappGroupName: "" });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingBatchGroup, setEditingBatchGroup] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Schema for Joi validation
  const schema = Joi.object({
    name: Joi.string().min(3).max(255).required().label("Batch Group Name"),
    whatsappGroupName: Joi.string()
      .allow(null, "")
      .max(255)
      .label("WhatsApp Group Name"),
  });

  useEffect(() => {
    fetchBatchGroups();
  }, []);

  const fetchBatchGroups = async () => {
    try {
      const response = await axiosInstance.get("/batch-groups-with-count");
      setBatchGroups(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
    console.log(message);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    try {
      if (editingBatchGroup) {
        Swal.fire({
          title: "Are you sure you want to update?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, update it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axiosInstance.put(
              `/batch-group/${editingBatchGroup.batchGroupId}`,
              formData
            );
            toast.success("Batch group updated successfully!");
            fetchBatchGroups();
            setShowModal(false);
            setFormData({ name: "", whatsappGroupName: "" });
            setEditingBatchGroup(null);
          }
        });
      } else {
        await axiosInstance.post("/batch-group", formData);
        toast.success("Batch group created successfully!");
        fetchBatchGroups();
        setShowModal(false);
        setFormData({ name: "", whatsappGroupName: "" });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (batchGroup) => {
    setEditingBatchGroup(batchGroup);
    setFormData({
      name: batchGroup.name,
      whatsappGroupName: batchGroup.whatsappGroupName,
    });
    setShowModal(true);
  };

  const handleDelete = (batchGroupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/batch-group/${batchGroupId}`);
          Swal.fire("Deleted!", "Batch group has been deleted.", "success");
          fetchBatchGroups();
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleAssignBatches = (groupId, whatsappGroupName) => {
    setSelectedGroupId(groupId);
    setSelectedGroupWhatsAppName(whatsappGroupName);
    setShowBatchGroupBatchesModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredBatchGroups = batchGroups.filter((batchGroup) =>
    Object.values(batchGroup)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentBatchGroups = filteredBatchGroups.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loader />;
  }

  return (
    <SettingLayout>
      <h1>Batch Groups</h1>

      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setShowModal(true)}
      >
        {editingBatchGroup ? "Edit Batch Group" : "Add Batch Group"}
      </Button>

      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Batch Group Name</th>
            <th>WhatsApp Group Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentBatchGroups.length === 0 ? (
            <tr>
              <td colSpan="4">No batch group found</td>
            </tr>
          ) : (
            currentBatchGroups.map((batchGroup) => (
              <tr key={batchGroup.batchGroupId}>
                <td>{batchGroup.batchGroupId}</td>
                <td>{batchGroup.name}</td>
                <td
                  style={
                    batchGroup.numberOfBatches < 2
                      ? { backgroundColor: "#ff9e9e" }
                      : {}
                  }
                >
                  {batchGroup.whatsappGroupName || "N/A"}
                  <Badge className="ms-3" bg="info">
                    {batchGroup.numberOfBatches}
                  </Badge>
                </td>
                <td>
                  <Button
                    variant="info"
                    onClick={() =>
                      handleAssignBatches(
                        batchGroup.batchGroupId,
                        batchGroup.whatsappGroupName
                      )
                    }
                    size="sm"
                    className="me-2"
                  >
                    Assign Batches
                  </Button>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(batchGroup)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(batchGroup.batchGroupId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredBatchGroups.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingBatchGroup ? "Edit Batch Group" : "Add New Batch Group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Batch Group Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="whatsappGroupName" className="mt-3">
              <Form.Label>WhatsApp Group Name</Form.Label>
              <Form.Control
                type="text"
                name="whatsappGroupName"
                value={formData.whatsappGroupName}
                onChange={handleInputChange}
                isInvalid={!!errors.whatsappGroupName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.whatsappGroupName}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Save Batch Group
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for assigning batches */}
      <Modal
        show={showBatchGroupBatchesModal}
        onHide={() => setShowBatchGroupBatchesModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedGroupWhatsAppName} - Assign Batches
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedGroupId && (
            <BatchGroupBatches
              groupId={selectedGroupId}
              onClose={() => setShowBatchGroupBatchesModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </SettingLayout>
  );
}

export default BatchGroups;
