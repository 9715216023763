import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Nav, Badge, ProgressBar } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2"; // Import SweetAlert2
import axiosInstance from "../utils/axiosInstance";
import Loader from "../Components/Loader"; // Assuming you have a loader component
import styled from "styled-components";
import AcademicLayout from "../Layout/AcademicLayout";

// Styled components for the table
const StyledThead = styled.thead`
  border: 2px solid black;
  th {
    border: 1px solid black;
  }
`;

const StyledTbody = styled.tbody`
  td {
    border: 1px solid black;
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
`;

const StatusBadge = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  background-color: ${(props) =>
    props.status === "not started"
      ? "gray"
      : props.status === "ongoing"
      ? "blue"
      : "green"};
  border-radius: 0.25em;
`;

function AcademicDelivery() {
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(
    "Level 5 Pearson BTEC HND"
  );
  const [batchesGrouped, setBatchesGrouped] = useState({});
  const [staffOptions, setStaffOptions] = useState([]); // Staff options for React-Select
  const [selectedStaff, setSelectedStaff] = useState(null); // Selected staff for React-Select
  const [currentSubject, setCurrentSubject] = useState(null); // Current subject being edited
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const [currentBatch, setCurrentBatch] = useState(null); // Track the current batch
  const [showBatchModal, setShowBatchModal] = useState(false);

  // Fetch staff data from backend
  const fetchStaffData = async () => {
    try {
      const response = await axiosInstance.get("/staff");
      const staffData = response.data.map((staff) => ({
        value: staff.staffId,
        label: `${staff.name} (${staff.employeeNumber})`,
      }));
      setStaffOptions(staffData);
    } catch (error) {
      console.error("Error fetching staff data", error);
    }
  };

    const handleTabSelect = (eventKey) => {
      setActiveTab(eventKey); // Update the state with the selected tab
    };

  const handleBatchClick = (batch) => {
    setCurrentBatch(batch);
    setShowBatchModal(true); // Show the modal for batch status change
  };

  // Fetch batches based on course
  const fetchBatches = async (courseName) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/batches-course-subjects?course=${courseName}`
      );
      const groupedData = groupBatchesByCourse(response.data);
      console.log(groupedData);
      setBatchesGrouped(groupedData);
    } catch (error) {
      console.error("Error fetching batches", error);
    } finally {
      setLoading(false);
    }
  };

  function calculateDays(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)); // Total days between start and end
    const passedDays = Math.max(
      Math.ceil((today - start) / (1000 * 60 * 60 * 24)),
      0
    ); // Days passed since start date
    const pendingDays = Math.max(totalDays - passedDays, 0); // Remaining days

    return { totalDays, passedDays, pendingDays };
  }



  // Group batches by course name
  const groupBatchesByCourse = (data) => {
    return data.reduce((acc, batch) => {
      const courseName = batch.fullName;

      if (!acc[courseName]) {
        acc[courseName] = [];
      }

      let batchSubjects = [];
      try {
        batchSubjects = JSON.parse(batch.batchSubjects);
      } catch (error) {
        console.error("Error parsing batchSubjects:", error);
      }

      const groupedBySemester = batchSubjects.reduce((semAcc, subject) => {
        const semesterKey = `Semester ${subject.semester || "Unknown"}`;
        if (!semAcc[semesterKey]) {
          semAcc[semesterKey] = [];
        }
        semAcc[semesterKey].push(subject);
        return semAcc;
      }, {});

      acc[courseName].push({
        ...batch,
        batchSubjects: groupedBySemester,
      });

      return acc;
    }, {});
  };

  const handleSaveBatchStatus = async () => {
    try {
      // Send the updated status to the backend using batch ID
      await axiosInstance.put(`/batch/${currentBatch.batchId}/status`, {
        status: currentBatch.status,
      });

      // Show a success message
      Swal.fire("Updated!", "The batch status has been updated.", "success");

      // Fetch the updated batches after saving
      fetchBatches(selectedOption);

      // Close the modal
      setShowBatchModal(false);
    } catch (error) {
      console.error("Error updating batch status", error);
      Swal.fire("Error!", "Failed to update the batch status.", "error");
    }
  };


  useEffect(() => {
    fetchStaffData(); // Fetch staff data on component mount
    fetchBatches(selectedOption); // Fetch batches based on selected course
  }, [selectedOption]);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  // Handle double-click event on a subject
  const handleDoubleClick = (subject) => {
    setCurrentSubject(subject);
    setSelectedStaff(
      staffOptions.find((staff) => staff.value === subject.staffId)
    ); // Pre-select the staff
    setShowModal(true); // Open modal
  };


  function calculateBatchProgress(startDate, endDate) {

    console.log(startDate);
    console.log(endDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    const totalDays = (end - start) / (1000 * 60 * 60 * 24); // Total batch duration in days
    const passedDays = (today - start) / (1000 * 60 * 60 * 24); // Days passed since the start date

    const progress = Math.min(Math.max((passedDays / totalDays) * 100, 0), 100); // Progress percentage (bounded between 0 and 100)
    const pendingDays = Math.max(
      Math.ceil((end - today) / (1000 * 60 * 60 * 24)),
      0
    ); // Pending days

    return { progress: Math.floor(progress), pendingDays };
  }

  // Handle save changes with SweetAlert confirmation
  const handleSaveChanges = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to update the batch subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Ensure we're using correct data for status
          const updatedSubject = {
            status: currentSubject.status, // Ensure status is taken from currentSubject, or from user input (selectedOption)
            staffId: selectedStaff ? selectedStaff.value : null, // Assign staff ID if selected
          };

          console.log("Updated Subject Data:", updatedSubject);

          // Make the API request to update the batch subject
          await axiosInstance.put(
            `/batch-assigned-subject/${currentSubject.batchSubjectId}`, // Make sure to use the correct subject ID
            updatedSubject
          );

          // Show success message
          Swal.fire(
            "Updated!",
            "The batch subject has been updated.",
            "success"
          );
          fetchBatches(selectedOption);
          setShowModal(false); // Close the modal after successful update
        } catch (error) {
          console.error("Error updating batch subject", error);
          Swal.fire("Error!", "Failed to update the batch subject.", "error");
        }
      }
    });
  };

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with leading zero
  const year = date.getFullYear(); // Get full year
  return `${day}-${month}-${year}`; // Return formatted date
}



  const handleClose = () => setShowModal(false); // Close modal

  if (loading) return <Loader />;

  return (
    <AcademicLayout>
      <h1>Academic Delivery</h1>
      <Nav
        className="mb-5"
        variant="tabs"
        activeKey={selectedOption}
        onSelect={handleSelect}
      >
        <Nav.Item>
          <Nav.Link eventKey="Level 5 Pearson BTEC HND">HND</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="BEng (Hons) in Software Engineering">
            BEng
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Nav
        className="mb-5"
        variant="pills"
        activeKey={activeTab} // Bind the state to the active tab
        onSelect={handleTabSelect} // Trigger handleTabSelect on selection
      >
        <Nav.Item>
          <Nav.Link eventKey="all">ALL</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="ongoing">Ongoing</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="notStarted">Not Started</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="deliveryCompleted">Delivery Completed</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="batchCompleted">Batch Completed</Nav.Link>
        </Nav.Item>
      </Nav>

      {/* Render batches grouped by course name */}
      {Object.entries(batchesGrouped).map(([courseName, batches]) => {
        // Filter the batches based on the activeTab (assuming this is the selected filter)
        const filteredBatches = batches.filter((batch) => {
          if (activeTab === "all") return true; // Show all batches
          if (activeTab === "ongoing") return batch.status === "ongoing";
          if (activeTab === "notStarted") return batch.status === "not started";
          if (activeTab === "deliveryCompleted")
            return batch.status === "delivery completed";
          if (activeTab === "batchCompleted")
            return batch.status === "batch completed";
          return false; // Fallback case, should not happen normally
        });

        // If no batches match the filter, don't render this course
        if (filteredBatches.length === 0) return null;

        return (
          <div key={courseName}>
            <h2 className="mb-5">{courseName}</h2>
            <StyledTable className="table">
              <StyledThead className="table-header">
                <tr>
                  <th rowSpan="2">Batch Name</th>
                  {Object.entries(filteredBatches[0].batchSubjects).map(
                    ([semester, subjects]) => (
                      <th key={semester} colSpan={subjects.length}>
                        {semester}
                      </th>
                    )
                  )}
                </tr>
                <tr>
                  {Object.entries(filteredBatches[0].batchSubjects).map(
                    ([semester, subjects]) =>
                      subjects.map((subject) => (
                        <th key={subject.batchSubjectId}>
                          {subject.subjectShortName}
                        </th>
                      ))
                  )}
                </tr>
              </StyledThead>
              <StyledTbody>
                {filteredBatches.map((batch) => (
                  <React.Fragment key={batch.batchId}>
                    <tr>
                      <td
                        rowSpan={2}
                        onDoubleClick={() => handleBatchClick(batch)}
                      >
                        {batch.batchName}
                      </td>
                      {Object.entries(batch.batchSubjects).map(
                        ([semester, subjects]) =>
                          subjects.map((subject) => {
                            const isEndDatePassed =
                              new Date(subject.endDate) < new Date();
                            const isCompleted = subject.status === "completed";

                            let backgroundColor = "";
                            if (isEndDatePassed && !isCompleted) {
                              backgroundColor = "lightcoral"; // Light red for overdue and not completed
                            } else if (isEndDatePassed && isCompleted) {
                              backgroundColor = "lightgray"; // Light gray for overdue but completed
                            }

                            return (
                              <td
                                key={subject.batchSubjectId}
                                style={{ backgroundColor }} // Apply background color based on logic
                                onDoubleClick={() => handleDoubleClick(subject)}
                              >
                                <Badge
                                  bg={
                                    subject.status === "not started"
                                      ? "secondary"
                                      : subject.status === "ongoing"
                                      ? "primary"
                                      : subject.status === "completed"
                                      ? "success"
                                      : "danger"
                                  }
                                >
                                  {subject.status}
                                </Badge>
                                <hr />
                                {subject.staffName && (
                                  <div>
                                    <small>{subject.staffName}</small>
                                  </div>
                                )}
                                {subject.staffName && <hr />}
                                <small>
                                  <strong>From:</strong>{" "}
                                  {formatDate(subject.startDate)}{" "}
                                  <strong>To:</strong>{" "}
                                  {formatDate(subject.endDate)}
                                </small>
                              </td>
                            );
                          })
                      )}
                    </tr>
                    <tr>
                      <td
                        colSpan={
                          Object.values(batch.batchSubjects).flat().length + 1
                        }
                      >
                        {/* Use the calculateBatchProgress function */}
                        {(() => {
                          const { progress, pendingDays } =
                            calculateBatchProgress(
                              batch.batchStartingDate,
                              batch.batchEndingDate
                            );

                          return (
                            <>
                              <div className="d-flex justify-content-between">
                                <small>{`Progress: ${progress}%`}</small>
                                <small>{`Pending: ${pendingDays} days`}</small>
                              </div>
                              <ProgressBar
                                now={progress}
                                label={`${progress}%`}
                                variant={
                                  progress < 33
                                    ? "success" // Green for low progress
                                    : progress < 66
                                    ? "warning" // Yellow for mid-level progress
                                    : "danger" // Red for high progress
                                }
                              />{" "}
                              {/* Display Batch Start and End Date */}
                              <div className="mt-2">
                                <small className="d-flex gap-2">
                                  <strong>Batch Start Date:</strong>{" "}
                                  {formatDate(batch.batchStartingDate)} <br />
                                  <strong className="ms-3">
                                    Batch End Date:
                                  </strong>{" "}
                                  {formatDate(batch.batchEndingDate)} <br />
                                  <strong className="ms-3">
                                    Total Days:
                                  </strong>{" "}
                                  {
                                    calculateDays(
                                      batch.batchStartingDate,
                                      batch.batchEndingDate
                                    ).totalDays
                                  }{" "}
                                  <strong className="ms-3">Passed Days:</strong>{" "}
                                  {
                                    calculateDays(
                                      batch.batchStartingDate,
                                      batch.batchEndingDate
                                    ).passedDays
                                  }{" "}
                                  <br />
                                  <strong className="ms-3">
                                    Pending Days:
                                  </strong>{" "}
                                  {
                                    calculateDays(
                                      batch.batchStartingDate,
                                      batch.batchEndingDate
                                    ).pendingDays
                                  }
                                </small>
                              </div>
                            </>
                          );
                        })()}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </StyledTbody>
            </StyledTable>
          </div>
        );
      })}

      {/* Modal for editing batch subject */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Batch Subject</Modal.Title>
        </Modal.Header>
        {currentSubject && (
          <Modal.Body>
            <Form>
              <Form.Group controlId="formSubjectName">
                <Form.Label>Subject Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={currentSubject.subjectName}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={currentSubject.status}
                  onChange={(e) =>
                    setCurrentSubject({
                      ...currentSubject,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="not started">Not Started</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="completed">Completed</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formStaffName">
                <Form.Label>Assign Staff</Form.Label>
                <Select
                  value={selectedStaff}
                  onChange={(option) => setSelectedStaff(option)}
                  options={staffOptions}
                  placeholder="Select staff"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Batch Status Change Modal */}
      <Modal show={showBatchModal} onHide={() => setShowBatchModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Batch Status</Modal.Title>
        </Modal.Header>
        {currentBatch && (
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBatchStatus">
                <Form.Label>Batch Name</Form.Label>
                <Form.Control
                  type="text"
                  value={currentBatch.batchName}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={currentBatch.status || "not started"} // Default to 'not started' if no status is set
                  onChange={(e) =>
                    setCurrentBatch({ ...currentBatch, status: e.target.value })
                  }
                >
                  <option value="not started">Not Started</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="delivery completed">Delivery Completed</option>
                  <option value="batch completed">Batch Completed</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBatchModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveBatchStatus}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </AcademicLayout>
  );
}

export default AcademicDelivery;
