import TimetableLayout from "../Layout/TimetableLayout";

function Timetable() {
    return (
      <TimetableLayout>
        <h1>Timetable</h1>
      </TimetableLayout>
    );
}

export default Timetable;