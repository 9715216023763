import DashboardLayout from "./DashboardLayout";



function SettingLayout({children}) {
  // Navigation items (sample data)
  const navigationItems = [
    { label: "Positions", path: "/settings/positions" },
    { label: "Courses", path: "/settings/courses" },
    { label: "Subjects", path: "/settings/subjects" },
    { label: "Assign Subjects", path: "/settings/assignSubjects" },
    { label: "Class Rooms", path: "/settings/classrooms" },
    { label: "Batches", path: "/settings/batches" },
    { label: "Staffs", path: "/settings/staffs" },
    { label: "Batch Groups", path: "/settings/batchGroups" },
    { label: "Staffs Rosters", path: "/settings/staffsRosters" },
  ];

  return (
    <DashboardLayout navigationItems={navigationItems}>{children}</DashboardLayout>
  );
}

export default SettingLayout;