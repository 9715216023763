import DashboardLayout from "./DashboardLayout";



function AcademicLayout({children}) {
  // Navigation items (sample data)
  const navigationItems = [
    { label: "Delivery", path: "/academic/academicDelivery" },
    { label: "Batch Subjects", path: "/academic/batchSubjects" },
  ];

  return (
    <DashboardLayout navigationItems={navigationItems}>
      {children}
    </DashboardLayout>
  );
}

export default AcademicLayout;