import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaSearch, FaHome, FaUser, FaCog, FaSignOutAlt } from "react-icons/fa";
import { CiMemoPad } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation for active link detection
import AuthService from "../services/authService"; // Assume you have a service to get user info
import { SlCalender } from "react-icons/sl";
import { GiBookshelf } from "react-icons/gi";

// Styled Components
const SidebarContainer = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const Username = styled.h5`
  margin: 0;
  font-size: 1.1rem;
`;

const UserTitle = styled.small`
  color: #888;
`;

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 20px;

  &:hover {
    color: #d9534f;
  }

  &:active,
  &:focus {
    background-color: #d9534f;
    color: #fff;
  }
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
  background-color: #e9ecef;
  border-radius: 4px;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: #6c757d;
  }
`;

const SearchIcon = styled.div`
  padding: 5px;
  color: #6c757d;
`;

const NavMenuContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 10px;

  &:hover {
    background-color: #e9ecef;
    color: #007bff;
  }

  &.active {
    background-color: #007bff;
    color: #fff;
  }
`;

const Sidebar = () => {
  const superAdmin = AuthService.getUserInfo(); // Replace with the actual method to get user info
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const user = AuthService.getUserInfo();


  // Navigation items
  const navigationItems = [
    { label: "Dashboard", icon: <FaHome />, path: "/dashboard" },
    { label: "Timetable", icon: <SlCalender />, path: "/timetable" },
    { label: "Academic", icon: <GiBookshelf />, path: "/academic" },
    { label: "Settings", icon: <FaCog />, path: "/settings" },
    { label: "Notepad", icon: <CiMemoPad />, path: "/notepad" },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  // Filter navigation items based on the search query
  const filteredItems = navigationItems.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleLogout = () => {
    // Clear user data from local storage or session storage
    AuthService.clearAuth(); // This method would clear the token and user data from storage

    // Optionally, notify the server about the logout (if needed)
    // Example: axios.post('/api/logout').then(() => { /* handle response */ });

    // Redirect to the login page
    navigate("/");
  };

  // If the user is null, return null or a loading indicator
  if (!user) {
    return null; // Or you could return a loading spinner or a message
  }

  return (
    <SidebarContainer>
      {/* User Info */}
      <UserInfo>
        <UserImage
          src={user.staff?.imagePath || "https://via.placeholder.com/50"}
          alt="User"
        />
        <div>
          <Username>{user.staff?.name || "Unknown User"}</Username>
          <UserTitle>{user.username}</UserTitle>
        </div>
      </UserInfo>

      {/* Logout Button */}
      <LogoutButton onClick={handleLogout}>
        <FaSignOutAlt style={{ marginRight: "10px" }} />
        Logout
      </LogoutButton>

      {/* Search Box */}
      <SearchBox>
        <SearchInput
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchBox>

      {/* Navigation Menu */}
      <NavMenuContainer>
        <NavMenu>
          {filteredItems.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={location.pathname.includes(item.path) ? "active" : ""}
            >
              <div style={{ marginRight: "10px" }}>{item.icon}</div>
              {item.label}
            </NavLink>
          ))}
        </NavMenu>
      </NavMenuContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
