import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination"; // Assuming you have a Pagination component

function ClassRooms() {
  const [classrooms, setClassrooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", floor: "" });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingClassroom, setEditingClassroom] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust the rows per page as needed

  // Schema for Joi validation
  const schema = Joi.object({
    name: Joi.string().min(3).max(255).required().label("Classroom Name"),
    floor: Joi.string().min(1).max(50).required().label("Floor"),
  });

  useEffect(() => {
    fetchClassrooms();
  }, []);

  const fetchClassrooms = async () => {
    try {
      const response = await axiosInstance.get("/classrooms");
      setClassrooms(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
    console.log(message);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors || {});

    if (validationErrors) return;

    try {
      if (editingClassroom) {
        // Update existing classroom
        await axiosInstance.put(
          `/classroom/${editingClassroom.classroomId}`,
          formData
        );
        toast.success("Classroom updated successfully!");
      } else {
        // Create new classroom
        await axiosInstance.post("/classroom", formData);
        toast.success("Classroom created successfully!");
      }

      fetchClassrooms(); // Refresh classrooms list
      setShowModal(false);
      setFormData({ name: "", floor: "" });
      setEditingClassroom(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (classroom) => {
    setEditingClassroom(classroom);
    setFormData({ name: classroom.name, floor: classroom.floor });
    setShowModal(true);
  };

  const handleDelete = (classroomId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/classroom/${classroomId}`);
          Swal.fire("Deleted!", "Classroom has been deleted.", "success");
          fetchClassrooms(); // Refresh classrooms list
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Updated filtering logic to search across all columns
  const filteredClassrooms = classrooms.filter((classroom) =>
    Object.values(classroom)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentClassrooms = filteredClassrooms.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loader />; // Show the full-screen loader while loading
  }

  return (
    <SettingLayout>
      <h1>Classrooms</h1>

      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setShowModal(true)}
      >
        {editingClassroom ? "Edit Classroom" : "Add Classroom"}
      </Button>

      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Classroom Name</th>
            <th>Floor</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentClassrooms.length === 0 ? (
            <tr>
              <td colSpan="4">No classroom found</td>
            </tr>
          ) : (
            currentClassrooms.map((classroom) => (
              <tr key={classroom.classroomId}>
                <td>{classroom.classroomId}</td>
                <td>{classroom.name}</td>
                <td>{classroom.floor}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(classroom)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(classroom.classroomId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredClassrooms.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingClassroom ? "Edit Classroom" : "Add New Classroom"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Classroom Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="floor" className="mt-3">
              <Form.Label>Floor</Form.Label>
              <Form.Control
                type="text"
                name="floor"
                value={formData.floor}
                onChange={handleInputChange}
                isInvalid={!!errors.floor}
              />
              <Form.Control.Feedback type="invalid">
                {errors.floor}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Classroom
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </SettingLayout>
  );
}

export default ClassRooms;
