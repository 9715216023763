import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const BatchDateCalculator = () => {
  const [showCalculateModal, setShowCalculateModal] = useState(false);
  const [batchStartDate, setBatchStartDate] = useState("");
  const [registrationOpenedDate, setRegistrationOpenedDate] = useState("");
  const [registrationClosingDate, setRegistrationClosingDate] = useState("");
  const [batchEndingDate, setBatchEndingDate] = useState("");
  const [numberOfMonths, setNumberOfMonths] = useState(0);

  const handleBatchStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setBatchStartDate(e.target.value);

    // Calculate Registration Opened Date (1 month before)
    const openedDate = new Date(selectedDate);
    openedDate.setMonth(openedDate.getMonth() - 1);
    setRegistrationOpenedDate(openedDate.toISOString().split("T")[0]);

    // Calculate Registration Closing Date (1 month after)
    const closingDate = new Date(selectedDate);
    closingDate.setMonth(closingDate.getMonth() + 1);
    setRegistrationClosingDate(closingDate.toISOString().split("T")[0]);
  };

  const handleNumberOfMonthsChange = (e) => {
    const months = parseInt(e.target.value);
    setNumberOfMonths(months);

    if (batchStartDate) {
      // Calculate Batch Ending Date (batchStartDate + numberOfMonths)
      const endingDate = new Date(batchStartDate);
      endingDate.setMonth(endingDate.getMonth() + months);
      setBatchEndingDate(endingDate.toISOString().split("T")[0]);
    }
  };

  return (
    <>
      <Button onClick={() => setShowCalculateModal(true)}>
        Calculate Batch Dates
      </Button>

      <Modal
        show={showCalculateModal}
        onHide={() => setShowCalculateModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Batch Date Calculations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4">
              <Form.Label>Batch Start Date</Form.Label>
              <Form.Control
                type="date"
                value={batchStartDate}
                onChange={handleBatchStartDateChange}
                className="form-control"
                placeholder="Select Batch Start Date"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Number of Months for Batch</Form.Label>
              <Form.Control
                type="number"
                value={numberOfMonths}
                onChange={handleNumberOfMonthsChange}
                placeholder="Enter number of months"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Registration Opened Date</Form.Label>
              <Form.Control
                type="text"
                value={registrationOpenedDate}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Registration Closing Date</Form.Label>
              <Form.Control
                type="text"
                value={registrationClosingDate}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Batch Ending Date</Form.Label>
              <Form.Control type="text" value={batchEndingDate} readOnly />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCalculateModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BatchDateCalculator;
