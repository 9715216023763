import DashboardLayout from "./DashboardLayout";



function TimetableLayout({children}) {
  // Navigation items (sample data)
  const navigationItems = [
    { label: "Batch Tracking", path: "/timetable/batchTracking" },
    { label: "Unavailable", path: "/timetable/unavailable" },
  ];

  return (
    <DashboardLayout navigationItems={navigationItems}>{children}</DashboardLayout>
  );
}

export default TimetableLayout;