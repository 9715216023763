import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
  Spinner,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import Loader from "../Components/Loader";
import Pagination from "../Components/Pagination";
import SettingLayout from "../Layout/SettingLayout";

function AssignSubjects() {
  const [courses, setCourses] = useState([]);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubject, setLoadingSubject] = useState(true);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [showSubjectAssignModal, setShowSubjectAssignModal] = useState(false);
  const [showEditSubjectModal, setShowEditSubjectModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [subjectToEdit, setSubjectToEdit] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSubjectTerm, setSearchSubjectTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchCourses();
    fetchAllSubjects();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get("/courses");
      setCourses(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssignedSubjects = async (courseId) => {
    setLoadingSubject(true);
    try {
      const response = await axiosInstance.get(`/courses/${courseId}/subjects`);
      setAssignedSubjects(response.data);
      setSelectedSubjects(response.data.map((subject) => subject.subjectId));
      setLoadingSubject(false);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error without showing a toast
      setLoadingSubject(false);
      setAssignedSubjects([]);
      setSelectedSubjects([]);
        // console.warn("Assigned subjects not found for this course.");
      } else {
        handleError(error);
      }
    }
  };

  const fetchAllSubjects = async () => {
    try {
      const response = await axiosInstance.get("/subjects");
      setAllSubjects(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    if (!(error.response && error.response.status === 404)) {
      toast.error(message);
    }
    console.error(message);
  };

  const handleShowSubjects = (course) => {
    setCurrentCourse(course);
    fetchAssignedSubjects(course.courseId);
    setShowSubjectModal(true);
  };

  const handleShowSubjectsAssign = (course) => {
    setCurrentCourse(course);
    fetchAssignedSubjects(course.courseId);
    setShowSubjectAssignModal(true);
  };

  const handleUpdateSubject = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(
        `/courses/${currentCourse.courseId}/subjects/${subjectToEdit.subjectId}`,
        subjectToEdit
      );
      toast.success("Subject updated successfully!");
      fetchAssignedSubjects(currentCourse.courseId);
      setShowEditSubjectModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleInputChange = (e) => {
    setSubjectToEdit({ ...subjectToEdit, [e.target.name]: e.target.value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSubjectSearch = (e) => {
    setSearchSubjectTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

const handleSubjectSelection = (subjectId) => {
  setSelectedSubjects((prevSelectedSubjects) => {
    if (prevSelectedSubjects.includes(subjectId)) {
      // If the subject is already selected, remove it
      return prevSelectedSubjects.filter((id) => id !== subjectId);
    } else {
      // If the subject is not selected, add it
      return [...prevSelectedSubjects, subjectId];
    }
  });
};

 const handleSaveSubjects = async () => {
   try {
     await axiosInstance.post(
       `/course-subjects/bulk`, // Updated path
       {
         courseId: currentCourse.courseId, // Include courseId in the request body
         subjectIds: selectedSubjects,
       }
     );
     toast.success("Subjects assigned successfully!");
     setShowSubjectModal(false);
     fetchAssignedSubjects(currentCourse.courseId);
   } catch (error) {
     handleError(error);
   }
 };





  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredCourses = courses.filter((course) =>
    Object.values(course)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  

  const currentCourses = filteredCourses.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredSubjects = allSubjects.filter((subject) =>
    Object.values(subject)
      .join(" ")
      .toLowerCase()
      .includes(searchSubjectTerm.toLowerCase())
  );

  if (loading) {
    return <Loader />;
  }

  

  return (
    <SettingLayout>
      <h1>Course List</h1>
      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Short Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCourses.length === 0 ? (
            <tr>
              <td colSpan="4">No course found</td>
            </tr>
          ) : (
            currentCourses.map((course) => (
              <tr key={course.courseId}>
                <td>{course.courseId}</td>
                <td>{course.fullName}</td>
                <td>{course.shortName}</td>
                <td>
                  <Button
                    variant="info"
                    className="me-2"
                    size="sm"
                    onClick={() => handleShowSubjects(course)}
                  >
                    Show Subjects
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleShowSubjectsAssign(course)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredCourses.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showSubjectModal} onHide={() => setShowSubjectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Subjects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingSubject && <Spinner size="sm" />}
          <Form>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Short Name</th>
                </tr>
              </thead>
              <tbody>
                {selectedSubjects.length === 0 ? (
                  <tr>
                    <td colSpan="3">No subject assigned</td>
                  </tr>
                ) : (
                  allSubjects
                    .filter((subject) =>
                      selectedSubjects.includes(subject.subjectId)
                    )
                    .map((subject) => (
                      <tr key={subject.subjectId}>
                        <td>{subject.subjectId}</td>
                        <td>{subject.fullName}</td>
                        <td>{subject.shortName}</td>
                      </tr>
                    ))
                )}
              </tbody>
            </Table>
            <span>Rows : {selectedSubjects.length}</span>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSubjectAssignModal}
        onHide={() => setShowSubjectAssignModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Subjects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {loadingSubject && <Spinner size="sm" />}
            <InputGroup>
              <FormControl
                placeholder="Search"
                value={searchSubjectTerm}
                onChange={handleSubjectSearch}
                className="mb-3"
              />
            </InputGroup>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Short Name</th>
                </tr>
              </thead>
              <tbody>
                {filteredSubjects.length === 0 ? (
                  <tr>
                    <td colSpan="4">No subject found</td>
                  </tr>
                ) : (
                  filteredSubjects.map((subject) => (
                    <tr key={subject.subjectId}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          value={subject.subjectId}
                          checked={selectedSubjects.includes(subject.subjectId)}
                          onChange={() =>
                            handleSubjectSelection(subject.subjectId)
                          }
                        />
                      </td>
                      <td>{subject.subjectId}</td>
                      <td>{subject.fullName}</td>
                      <td>{subject.shortName}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <Button
              variant="primary"
              onClick={handleSaveSubjects}
              disabled={selectedSubjects.length === 0}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditSubjectModal}
        onHide={() => setShowEditSubjectModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdateSubject}>
            <Form.Group controlId="formSubjectFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={subjectToEdit ? subjectToEdit.fullName : ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSubjectShortName">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                name="shortName"
                value={subjectToEdit ? subjectToEdit.shortName : ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </SettingLayout>
  );
}

export default AssignSubjects;
