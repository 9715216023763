import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination"; // Assuming you have a Pagination component
import axios from "axios";

function Positions() {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ positionName: "" });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingPosition, setEditingPosition] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust the rows per page as needed

  // Schema for Joi validation
  const schema = Joi.object({
    positionName: Joi.string()
      .min(3)
      .max(255)
      .required()
      .label("Position Name"),
  });

  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await axiosInstance.get("/positions");
      setPositions(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };


  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
    console.log(message);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    // setErrors(validationErrors || {});

    if (validationErrors) return;

    try {
      if (editingPosition) {
        // Update existing position
        await axiosInstance.put(
          `/position/${editingPosition.positionId}`,
          formData
        );
        toast.success("Position updated successfully!");
      } else {
        // Create new position
        await axiosInstance.post("/position", formData);
        toast.success("Position created successfully!");
      }

      fetchPositions(); // Refresh positions list
      setShowModal(false);
      setFormData({ positionName: "" });
      setEditingPosition(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (position) => {
    setEditingPosition(position);
    setFormData({ positionName: position.positionName });
    setShowModal(true);
  };

  const handleDelete = (positionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/position/${positionId}`);
          Swal.fire("Deleted!", "Position has been deleted.", "success");
          fetchPositions(); // Refresh positions list
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Updated filtering logic to search across all columns
  const filteredPositions = positions.filter((position) =>
    Object.values(position)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentPositions = filteredPositions.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loader />; // Show the full-screen loader while loading
  }

  return (
    <SettingLayout>
      <h1>Positions</h1>

      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setShowModal(true)}
      >
        {editingPosition ? "Edit Position" : "Add Position"}
      </Button>

      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Position Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPositions.length === 0 ? (
            <tr>
              <td colSpan="3">No position found</td>{" "}
              {/* Adjusted colSpan to cover all columns */}
            </tr>
          ) : (
            currentPositions.map((position) => (
              <tr key={position.positionId}>
                <td>{position.positionId}</td>
                <td>{position.positionName}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(position)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(position.positionId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredPositions.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingPosition ? "Edit Position" : "Add New Position"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="positionName">
              <Form.Label>Position Name</Form.Label>
              <Form.Control
                type="text"
                name="positionName"
                value={formData.positionName}
                onChange={handleInputChange}
                isInvalid={!!errors.positionName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.positionName}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Save Position
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </SettingLayout>
  );
}

export default Positions;
