import DashboardLayout from "./DashboardLayout";



function NotepadLayout({children}) {
  // Navigation items (sample data)
  const navigationItems = [
    { label: "Student Issue", path: "/notepad/studentIssue" },
    { label: "To Do List", path: "/notepad/toDoList" },
  ];

  return (
    <DashboardLayout navigationItems={navigationItems}>{children}</DashboardLayout>
  );
}

export default NotepadLayout;