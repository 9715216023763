import React, { useState, useEffect } from "react";
import NotepadLayout from "../Layout/NotepadLayout";
import { Button, Modal, Form, Card, Row, Col, Nav } from "react-bootstrap";
import { FaTrash } from "react-icons/fa"; // Import Trash icon
import {
  format,
  isSameDay,
  isSameWeek,
  isWithinInterval,
  startOfWeek,
  endOfWeek,
} from "date-fns"; // Date-fns imports
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

// Styled components for task display
const TaskRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TaskTitle = styled.h5`
  margin: 0 10px;
  text-decoration: ${({ completed }) => (completed ? "line-through" : "none")};
  font-size: 1.1rem;
  font-weight: 600;
`;

const TaskDescription = styled.p`
  margin: 0 10px;
  text-decoration: ${({ completed }) => (completed ? "line-through" : "none")};
  color: #666;
  font-size: 0.9rem;
`;

const TaskCard = styled(Card)`
  margin-bottom: 10px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const DeleteButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #dc3545;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
`;

const DateHeader = styled.h4`
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: bold;
`;

function ToDoList() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    task_name: "",
    description: "",
    due_date: "",
  });
  const [errors, setErrors] = useState({});
  const [editingTask, setEditingTask] = useState(null);
  const [view, setView] = useState("week"); // Controls the current view (day, week, month, custom)
  const [customRange, setCustomRange] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get("/tasks");
      setTasks(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTask) {
        await axiosInstance.put(`/task/${editingTask.id}`, formData);
        toast.success("Task updated successfully!");
      } else {
        await axiosInstance.post("/task", { ...formData, status: "pending" });
        toast.success("Task created successfully!");
      }

      fetchTasks();
      setShowModal(false);
      setFormData({ task_name: "", description: "", due_date: "" });
      setEditingTask(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (taskId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/task/${taskId}`);
          Swal.fire("Deleted!", "Task has been deleted.", "success");
          fetchTasks();
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleStatusChange = async (taskId, currentStatus) => {
    const newStatus = currentStatus === "pending" ? "complete" : "pending";
    try {
      await axiosInstance.put(`/task/${taskId}/status`, { status: newStatus });
      fetchTasks(); // Refresh tasks
    } catch (error) {
      handleError(error);
    }
  };

  // Helper function to filter tasks based on the current view
  const filterTasksByView = (tasks) => {
    const today = new Date();

    if (view === "day") {
      return tasks.filter((task) => isSameDay(new Date(task.due_date), today));
    } else if (view === "week") {
      const start = startOfWeek(today);
      const end = endOfWeek(today);
      return tasks.filter((task) =>
        isWithinInterval(new Date(task.due_date), { start, end })
      );
    } else if (
      view === "custom" &&
      customRange.startDate &&
      customRange.endDate
    ) {
      return tasks.filter((task) =>
        isWithinInterval(new Date(task.due_date), {
          start: customRange.startDate,
          end: customRange.endDate,
        })
      );
    }

    // Default to showing all tasks (Month view can be managed similarly)
    return tasks;
  };

  const tasksForView = filterTasksByView(tasks);

  // Helper function to group tasks by due date
  const groupTasksByDate = (tasks) => {
    const groupedTasks = tasks.reduce((acc, task) => {
      const taskDate = format(new Date(task.due_date), "yyyy-MM-dd");
      if (!acc[taskDate]) {
        acc[taskDate] = [];
      }
      acc[taskDate].push(task);
      return acc;
    }, {});
    return groupedTasks;
  };

  const groupedTasks = groupTasksByDate(tasksForView);

  if (loading) {
    return <Loader />;
  }

  return (
    <NotepadLayout>
      <h1>To-Do List</h1>

      {/* Navigation to switch between views */}
      <Nav variant="underline" className="mb-3">
        <Nav.Item>
          <Nav.Link onClick={() => setView("day")} active={view === "day"}>
            Day View
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => setView("week")} active={view === "week"}>
            Week View
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => setView("month")} active={view === "month"}>
            Month View
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => setView("custom")}
            active={view === "custom"}
          >
            Custom Date Range
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {/* Custom date range selection */}
      {view === "custom" && (
        <Row className="mb-4">
          <Col>
            <DatePicker
              selected={customRange.startDate}
              onChange={(date) =>
                setCustomRange((prev) => ({ ...prev, startDate: date }))
              }
              placeholderText="Start Date"
              className="form-control"
            />
          </Col>
          <Col>
            <DatePicker
              selected={customRange.endDate}
              onChange={(date) =>
                setCustomRange((prev) => ({ ...prev, endDate: date }))
              }
              placeholderText="End Date"
              className="form-control"
            />
          </Col>
        </Row>
      )}

      {/* Add task button */}
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Add Task
      </Button>

      {/* Task List Display, Grouped by Date */}
      <div className="mt-4">
        {Object.keys(groupedTasks).length > 0 ? (
          Object.keys(groupedTasks).map((date) => (
            <div key={date}>
              <DateHeader>
                {format(new Date(date), "EEEE, MMMM d, yyyy")}
              </DateHeader>
              {groupedTasks[date].map((task) => (
                <TaskCard key={task.id}>
                  <TaskRow>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check
                        type="checkbox"
                        checked={task.status === "complete"}
                        onChange={() =>
                          handleStatusChange(task.id, task.status)
                        }
                      />
                      <TaskTitle completed={task.status === "complete"}>
                        {task.task_name}
                      </TaskTitle>
                    </div>
                    <DeleteButton onClick={() => handleDelete(task.id)}>
                      <FaTrash />
                    </DeleteButton>
                  </TaskRow>
                  <TaskDescription completed={task.status === "complete"}>
                    {task.description}
                  </TaskDescription>
                </TaskCard>
              ))}
            </div>
          ))
        ) : (
          <p>No tasks available.</p>
        )}
      </div>

      {/* Add/Edit Task Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingTask ? "Edit Task" : "Add New Task"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="task_name">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                name="task_name"
                value={formData.task_name}
                onChange={handleInputChange}
                isInvalid={!!errors.task_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.task_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="due_date" className="mt-3">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due_date"
                value={formData.due_date}
                onChange={handleInputChange}
                isInvalid={!!errors.due_date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.due_date}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Task
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </NotepadLayout>
  );
}

export default ToDoList;
