import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
  Badge,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination";
import BatchDateCalculator from "../Components/BatchDateCalculator";

function Batches() {
  const [batches, setBatches] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    batchName: "",
    courseId: "",
    registrationOpeningDate: "",
    batchStartingDate: "",
    registrationClosingDate: "",
    batchEndingDate: "",
    description: "",
    whatsappGroupName: "",
    status: "not started", // New field for status with default value
  });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingBatch, setEditingBatch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const schema = Joi.object({
    batchName: Joi.string().min(3).max(255).required().label("Batch Name"),
    courseId: Joi.number().required().label("Course ID"),
    registrationOpeningDate: Joi.date()
      .required()
      .label("Registration Opening Date"),
    batchStartingDate: Joi.date().required().label("Batch Starting Date"),
    registrationClosingDate: Joi.date()
      .required()
      .label("Registration Closing Date"),
    batchEndingDate: Joi.date().required().label("Batch Ending Date"),
    description: Joi.string().optional().allow("").label("Description"),
    whatsappGroupName: Joi.string()
      .optional()
      .allow("")
      .label("WhatsApp Group Name"),
    status: Joi.string()
      .valid("not started", "ongoing", "delivery completed", "batch completed")
      .required()
      .label("Status"), // Validation for status field
  });

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchBatches(selectedCourse);
  }, [selectedCourse]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get("/courses");
      setCourses(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBatches = async (courseId) => {
    try {
      let response;
      if (courseId) {
        response = await axiosInstance.get(`/courses/${courseId}/batches`);
      } else {
        response = await axiosInstance.get(`/batches`);
      }
      setBatches(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("No batches found for this course.");
        setBatches([]);
      } else {
        handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCourseSelect = (e) => {
    setSelectedCourse(e.target.value);
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors || {});

    if (validationErrors) return;

    try {
      if (editingBatch) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "You are about to update this batch. Do you want to continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update it!",
        });

        if (result.isConfirmed) {
          await axiosInstance.put(`/batch/${editingBatch.batchId}`, formData);
          toast.success("Batch updated successfully!");
        } else {
          return;
        }
      } else {
        await axiosInstance.post("/batch", formData);
        toast.success("Batch created successfully!");
      }

      fetchBatches(selectedCourse);
      resetForm();
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (batch) => {
    const formatDateForInput = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0]; // Extract the date part in YYYY-MM-DD format
    };

    setEditingBatch(batch);
    setFormData({
      batchName: batch.batchName,
      courseId: batch.courseId,
      registrationOpeningDate: formatDateForInput(
        batch.registrationOpeningDate
      ),
      batchStartingDate: formatDateForInput(batch.batchStartingDate),
      registrationClosingDate: formatDateForInput(
        batch.registrationClosingDate
      ),
      batchEndingDate: formatDateForInput(batch.batchEndingDate),
      description: batch.description,
      whatsappGroupName: batch.whatsappGroupName,
      status: batch.status, // Include status when editing
    });
    setShowModal(true);
  };

  const resetForm = () => {
    setShowModal(false);
    setFormData({
      batchName: "",
      courseId: "",
      registrationOpeningDate: "",
      batchStartingDate: "",
      registrationClosingDate: "",
      batchEndingDate: "",
      description: "",
      whatsappGroupName: "",
      status: "not started", // Reset status to default
    });
    setEditingBatch(null);
    setErrors({});
  };

  const handleDelete = (batchId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/batch/${batchId}`);
          Swal.fire("Deleted!", "Batch has been deleted.", "success");
          fetchBatches(selectedCourse);
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredBatches = batches.filter((batch) =>
    Object.values(batch)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentBatches = filteredBatches.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusBadge = (status) => {
    switch (status) {
      case "not started":
        return <Badge bg="secondary">Not Started</Badge>;
      case "ongoing":
        return <Badge bg="success">Ongoing</Badge>;
      case "delivery completed":
        return <Badge bg="primary">Delivery Completed</Badge>;
      case "batch completed":
        return <Badge bg="danger">Batch Completed</Badge>;
      default:
        return <Badge bg="light">Unknown</Badge>;
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <SettingLayout>
      <h1>Batches</h1>
      <Row>
        <Col className="d-flex justify-content-start align-items-center p-3">
          <Button
            variant="primary"
            className="mb-3 me-3"
            onClick={() => setShowModal(true)}
          >
            {editingBatch ? "Edit Batch" : "Add Batch"}
          </Button>
          <div className="ms-auto">
            <BatchDateCalculator />
          </div>
        </Col>
      </Row>

      <Row className="align-items-center mb-3">
        <Col xs={12} md={8}>
          <Row className="g-3">
            <Col xs={4} md={2}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="25">25 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
            <Col xs={8} md={10}>
              <FormSelect
                value={selectedCourse}
                onChange={handleCourseSelect}
                className="mb-3"
              >
                <option value="">Select a Course</option>
                {courses.map((course) => (
                  <option key={course.courseId} value={course.courseId}>
                    {`${course.fullName} - ${course.scheduleType}`}
                  </option>
                ))}
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Batch Name</th>
            <th>Registration Opening Date</th>
            <th>Registration Closing Date</th>
            <th>Batch Starting Date</th>
            <th>Batch Ending Date</th>
            <th>WhatsApp Group</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentBatches.length === 0 ? (
            <tr>
              <td colSpan="9">No batch found</td>
            </tr>
          ) : (
            currentBatches.map((batch) => (
              <tr key={batch.batchId}>
                <td>{batch.batchId}</td>
                <td>{batch.batchName}</td>
                <td>{formatDate(batch.registrationOpeningDate)}</td>
                <td>{formatDate(batch.registrationClosingDate)}</td>
                <td>{formatDate(batch.batchStartingDate)}</td>
                <td>{formatDate(batch.batchEndingDate)}</td>
                <td>{batch.whatsappGroupName}</td>
                <td>{getStatusBadge(batch.status)}</td>{" "}
                {/* Use status from batch */}
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(batch)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(batch.batchId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Row>
        <Col>
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={filteredBatches.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Col>
        <Col className="text-end">{`Total ${currentBatches.length} Rows`}</Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingBatch ? "Edit Batch" : "Add New Batch"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="batchName" className="mb-4">
              <Form.Label>Batch Name</Form.Label>
              <Form.Control
                type="text"
                name="batchName"
                value={formData.batchName}
                onChange={handleInputChange}
                isInvalid={!!errors.batchName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.batchName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="courseId" className="mb-4">
              <Form.Label>Select Course</Form.Label>
              <FormSelect
                name="courseId"
                value={formData.courseId}
                onChange={handleInputChange}
                isInvalid={!!errors.courseId}
              >
                <option value="">Select a Course</option>
                {courses.map((course) => (
                  <option key={course.courseId} value={course.courseId}>
                    {`${course.fullName} - ${course.scheduleType}`}
                  </option>
                ))}
              </FormSelect>
              <Form.Control.Feedback type="invalid">
                {errors.courseId}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mb-4">
              <Col>
                <Form.Group controlId="registrationOpeningDate">
                  <Form.Label>Registration Opening Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="registrationOpeningDate"
                    value={formData.registrationOpeningDate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.registrationOpeningDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.registrationOpeningDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="batchStartingDate">
                  <Form.Label>Batch Starting Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="batchStartingDate"
                    value={formData.batchStartingDate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.batchStartingDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.batchStartingDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <Form.Group controlId="registrationClosingDate">
                  <Form.Label>Registration Closing Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="registrationClosingDate"
                    value={formData.registrationClosingDate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.registrationClosingDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.registrationClosingDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="batchEndingDate">
                  <Form.Label>Batch Ending Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="batchEndingDate"
                    value={formData.batchEndingDate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.batchEndingDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.batchEndingDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="description" className="mb-4">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="whatsappGroupName" className="mb-4">
              <Form.Label>WhatsApp Group Name</Form.Label>
              <Form.Control
                type="text"
                name="whatsappGroupName"
                value={formData.whatsappGroupName}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="status" className="mb-4">
              <Form.Label>Status</Form.Label>
              <FormSelect
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                isInvalid={!!errors.status}
              >
                <option value="not started">Not Started</option>
                <option value="ongoing">Ongoing</option>
                <option value="delivery completed">Delivery Completed</option>
                <option value="batch completed">Batch Completed</option>
              </FormSelect>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Batch
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </SettingLayout>
  );
}

export default Batches;
