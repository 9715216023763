// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// src/index.js or src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Positions from "./pages/Positions";
import Courses from "./pages/Courses";
import Subjects from "./pages/Subjects";
import ClassRooms from "./pages/ClassRooms";
import AssignSubjects from "./pages/AssignSubjects";
import Batches from "./pages/Batches";
import Staffs from "./pages/Staffs";
import BatchGroups from "./pages/BatchGroups";
import Notepad from "./pages/Notepad";
import StudentIssue from "./pages/StudentIssue";
import ToDoList from "./pages/ToDoList";
import Timetable from "./pages/Timetable";
import BatchTracking from "./pages/BatchTracking";
import Unavailable from "./pages/Unavailable";
import BatchSubjects from "./pages/BatchSubjects";
import Academic from "./pages/Dashboard copy";
import AcademicDelivery from "./pages/AcademicDelivery";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/positions" element={<Positions />} />
        <Route path="settings/courses" element={<Courses />} />
        <Route path="settings/subjects" element={<Subjects />} />
        <Route path="settings/assignSubjects" element={<AssignSubjects />} />
        <Route path="settings/classrooms" element={<ClassRooms />} />
        <Route path="/settings/batches" element={<Batches />} />
        <Route path="/settings/staffs" element={<Staffs />} />
        <Route path="/settings/batchGroups" element={<BatchGroups />} />
        {/* ======================TimeTable ============================== */}
        <Route path="/academic" element={<Academic />} />
        <Route path="/academic/batchSubjects" element={<BatchSubjects />} />
        <Route
          path="/academic/academicDelivery"
          element={<AcademicDelivery />}
        />
        {/* ======================TimeTable ============================== */}
        <Route path="/timetable" element={<Timetable />} />
        <Route path="/timetable/batchTracking" element={<BatchTracking />} />
        <Route path="/timetable/unavailable" element={<Unavailable />} />

        {/* ======================Notepad ============================== */}
        <Route path="/notepad" element={<Notepad />} />
        <Route path="/notepad/studentIssue" element={<StudentIssue />} />
        <Route path="/notepad/toDoList" element={<ToDoList />} />
      </Routes>
    </Router>
  );
}

export default App;
