import React, { useState, useEffect } from "react";
import SettingLayout from "../Layout/SettingLayout";
import {
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormSelect,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/axiosInstance";
import Pagination from "../Components/Pagination"; // Assuming you have a Pagination component

function Courses() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    shortName: "",
    duration: "",
    registrationFee: "",
    universityPayment: "",
    coursePayment: "",
    scheduleType: "", // Add scheduleType field
  });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingCourse, setEditingCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust the rows per page as needed

  // Schema for Joi validation
  const schema = Joi.object({
    fullName: Joi.string().min(3).max(255).required().label("Full Name"),
    shortName: Joi.string().min(2).max(50).required().label("Short Name"),
    duration: Joi.number().integer().required().label("Duration"),
    registrationFee: Joi.number().required().label("Registration Fee"),
    universityPayment: Joi.number().allow("").label("University Payment"),
    coursePayment: Joi.number().allow("").label("Course Payment"),
    scheduleType: Joi.string()
      .valid("weekday", "weekend")
      .required()
      .label("Schedule Type"), // Validate scheduleType
  });

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get("/courses");
      setCourses(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      "An unexpected error occurred.";
    toast.error(message);
    console.log(message);
  };

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (
      name === "registrationFee" ||
      name === "universityPayment" ||
      name === "coursePayment"
    ) {
      formattedValue = formatNumber(value);
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const validateForm = (data) => {
    const { error } = schema.validate(data, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parsedData = {
      ...formData,
      registrationFee: parseFloat(parseNumber(formData.registrationFee)),
      universityPayment: formData.universityPayment
        ? parseFloat(parseNumber(formData.universityPayment))
        : null,
      coursePayment: formData.coursePayment
        ? parseFloat(parseNumber(formData.coursePayment))
        : null,
    };

    const validationErrors = validateForm(parsedData);
    setErrors(validationErrors || {});

    if (validationErrors) return;

    try {
      if (editingCourse) {
        // Update existing course
        await axiosInstance.put(
          `/course/${editingCourse.courseId}`,
          parsedData
        );
        toast.success("Course updated successfully!");
      } else {
        // Create new course
        await axiosInstance.post("/course", parsedData);
        toast.success("Course created successfully!");
      }

      fetchCourses(); // Refresh courses list
      setShowModal(false);
      setFormData({
        fullName: "",
        shortName: "",
        duration: "",
        registrationFee: "",
        universityPayment: "",
        coursePayment: "",
        scheduleType: "",
      });
      setEditingCourse(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (course) => {
    setEditingCourse(course);
    setFormData({
      fullName: course.fullName,
      shortName: course.shortName,
      duration: course.duration,
      registrationFee: formatNumber(course.registrationFee.toString()),
      universityPayment: course.universityPayment
        ? formatNumber(course.universityPayment.toString())
        : "",
      coursePayment: course.coursePayment
        ? formatNumber(course.coursePayment.toString())
        : "",
      scheduleType: course.scheduleType || "", // Handle scheduleType
    });
    setShowModal(true);
  };

  const handleDelete = (courseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/course/${courseId}`);
          Swal.fire("Deleted!", "Course has been deleted.", "success");
          fetchCourses(); // Refresh courses list
        } catch (error) {
          handleError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Updated filtering logic to search across all columns
  const filteredCourses = courses.filter((course) =>
    Object.values(course)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentCourses = filteredCourses.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loader />; // Show the full-screen loader while loading
  }

  return (
    <SettingLayout>
      <ToastContainer />
      <h1>Courses</h1>

      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setShowModal(true)}
      >
        {editingCourse ? "Edit Course" : "Add Course"}
      </Button>

      <Row className="align-items-center mb-3">
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <FormSelect
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="75">75 rows</option>
                <option value="100">100 rows</option>
              </FormSelect>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="text-end">
          <InputGroup>
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Short Name</th>
            <th>Duration</th>
            <th>Registration Fee</th>
            <th>University Payment</th>
            <th>Course Payment</th>
            <th>Schedule Type</th> {/* Display schedule type */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCourses.length === 0 ? (
            <tr>
              <td colSpan="9">No course found</td>{" "}
              {/* Adjusted colSpan to cover all columns */}
            </tr>
          ) : (
            currentCourses.map((course) => (
              <tr key={course.courseId}>
                <td>{course.courseId}</td>
                <td>{course.fullName}</td>
                <td>{course.shortName}</td>
                <td>{course.duration}</td>
                <td>{formatNumber(course.registrationFee.toString())}</td>
                <td>
                  {course.universityPayment
                    ? formatNumber(course.universityPayment.toString())
                    : "N/A"}
                </td>
                <td>
                  {course.coursePayment
                    ? formatNumber(course.coursePayment.toString())
                    : "N/A"}
                </td>
                <td>{course.scheduleType || "N/A"}</td>{" "}
                {/* Display schedule type */}
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    size="sm"
                    onClick={() => handleEdit(course)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(course.courseId)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination
        rowsPerPage={rowsPerPage}
        totalRows={filteredCourses.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingCourse ? "Edit Course" : "Add New Course"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="fullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                isInvalid={!!errors.fullName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fullName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="shortName" className="mt-3">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                name="shortName"
                value={formData.shortName}
                onChange={handleInputChange}
                isInvalid={!!errors.shortName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.shortName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="duration" className="mt-3">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="number"
                name="duration"
                value={formData.duration}
                onChange={handleInputChange}
                isInvalid={!!errors.duration}
              />
              <Form.Control.Feedback type="invalid">
                {errors.duration}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="registrationFee" className="mt-3">
              <Form.Label>Registration Fee</Form.Label>
              <Form.Control
                type="text"
                name="registrationFee"
                value={formData.registrationFee}
                onChange={handleInputChange}
                isInvalid={!!errors.registrationFee}
              />
              <Form.Control.Feedback type="invalid">
                {errors.registrationFee}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="universityPayment" className="mt-3">
              <Form.Label>University Payment</Form.Label>
              <Form.Control
                type="text"
                name="universityPayment"
                value={formData.universityPayment}
                onChange={handleInputChange}
                isInvalid={!!errors.universityPayment}
              />
              <Form.Control.Feedback type="invalid">
                {errors.universityPayment}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="coursePayment" className="mt-3">
              <Form.Label>Course Payment</Form.Label>
              <Form.Control
                type="text"
                name="coursePayment"
                value={formData.coursePayment}
                onChange={handleInputChange}
                isInvalid={!!errors.coursePayment}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coursePayment}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="scheduleType" className="mt-3">
              <Form.Label>Schedule Type</Form.Label>
              <Form.Select
                name="scheduleType"
                value={formData.scheduleType}
                onChange={handleInputChange}
                isInvalid={!!errors.scheduleType}
              >
                <option value="">Select Schedule Type</option>
                <option value="weekday">Weekday</option>
                <option value="weekend">Weekend</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.scheduleType}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Save Course
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </SettingLayout>
  );
}

export default Courses;
